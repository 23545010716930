import { useState } from "react";

const FloatingTooltip = ({ children, tooltipText }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative inline-flex items-center justify-center p-2 rounded-lg transition"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}

      {/* Tooltip */}
      <div
        className={`absolute text-[14px] text-foreground1 font-mono border-foreground1 
          rounded-l-[16px] rounded-tr-[16px] bg-background1 border-[1px] 
          inline-flex text-center w-[190px] bottom-[100px] left-[-120px] 
          mb-[5px] px-[8px] py-[8px] shadow-[4px_4px_0px_rgba(0,0,0,100)] 
          transition-opacity duration-1000 ease-in-out pointer-events-none
          ${isHovered ? "opacity-100 visible" : "opacity-0 invisible"}`}
      >
        {tooltipText}
      </div>
    </div>
  );
};

export default FloatingTooltip;
