import React from "react";
import { useRef } from "react";
import {
  HighlightContainer,
  HighlightList,
  ImageWrapper,
} from "../NewComponents/CaseStudyComps";
import workflowImage from "../../../assets/ImageComparison/workflow.webp";
import currentIC from "../../../assets/ImageComparison/CurrentIC.webp";
import Hypothesis from "../../../assets/ImageComparison/Hypothesis.webp";
import FirstConcept from "../../../assets/ImageComparison/FirstConcept.webp";
import InitialQuestions from "../../../assets/ImageComparison/InitialQuestions.webp";
import Z1 from "../../../assets/ImageComparison/ZietView1.webp";
import Z2 from "../../../assets/ImageComparison/ZietView2.webp";
import Horizon from "../../../assets/ImageComparison/horizon.webp";
import LayoutDesign from "../../../assets/ImageComparison/layout.webp";
import WireframeIterations from "../../../assets/ImageComparison/WireframeIterations.webp";
import Gifanimation from "../../../assets/ImageComparison/ImageComparisonGif.gif";
import EnhancedHeader from "../../../assets/ImageComparison/Header.webp";
import EnhancedSymbole from "../../../assets/ImageComparison/workflowSymbols.webp";
import EnhancedCarousel from "../../../assets/ImageComparison/Carousel.webp";
import FinalUI1 from "../../../assets/ImageComparison/FinalUI1.webp";
import FinalUI2 from "../../../assets/ImageComparison/FinalUI2.webp";
import FinalUI3 from "../../../assets/ImageComparison/FinalUI3.webp";
import FinalUI4 from "../../../assets/ImageComparison/FinalUI4.webp";
import FinalUI5 from "../../../assets/ImageComparison/FinalUI5.webp";
import FinalUI6 from "../../../assets/ImageComparison/FinalUI6.webp";
import FinalUI7 from "../../../assets/ImageComparison/FinalUI7.webp";
import { FloatingNavigator } from "../../ui/FloatingNavigator";
import PasswordProtectedPage from "../../PasswordModal";
import TranscendTest from "../../../assets/Transcend/Transcend Test.webp";

const WhyIC = [
  "Standard practice throughout the industry, Having it will increase value of lifespan.",
  "Resolution Time of damages is high, Image comparison will decrease that.",
  "We have AI which helps engineers to mark damages, but it is not accurate, Image comparison will also help us to make AI better by feeding right data.",
];

export const ImageComparison1 = () => {
  const scrollRefs = {
    ProblemIdentification: useRef(null),
    TheBreakdown: useRef(null),
    ContextualEnquiry: useRef(null),
    HypothesisTesting: useRef(null),
    BenchmarkingStudy: useRef(null),
    Ideation: useRef(null),
    VisualDesigns: useRef(null),
    Learnings: useRef(null),

    // Add refs for all sections you need
  };
  const handleScroll = (section) => {
    //   if (section && section.current) {
    //     section.current.scrollIntoView({ behavior: "smooth", block: "start" });
    //     setTimeout(() => {
    //       window.scrollBy({
    //         top: -56, // Adjust this value based on your fixed header height
    //         behavior: "smooth",
    //       });
    //     }, 300);
    //   } else {
    //     console.error("Section is undefined or current is null:", section);
    //   }
    // };
    if (section && section.current) {
      const headerOffset = 80; // Adjust this value based on your header height
      const elementPosition =
        section.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      console.error("Section is undefined or current is null:", section);
    }
  };
  return (
    <PasswordProtectedPage>
      <div className="grid grid-cols-12  mt-[120px] gap-[16px] h-[auto] mx-[48px] max-w-[1470px] ]">
        <div className="col-span-2 col-start-11">
          <FloatingNavigator
            scrollRefs={scrollRefs}
            handleScroll={handleScroll}
          />
        </div>
        <div className="col-span-6 col-start-4 flex-col flex gap-[120px]">
          <div className="w-full flex flex-col gap-[24px]  ">
            <div className="w-full flex flex-col gap-[16px]">
              <p className="text-dh1 text-text1 font-[700] font-script">
                GE REN - Blade image comparison for engineers
              </p>
              <p className="font-sans text-db text-text2 tracking-[-0.3px] leading-[150%] font-[400] ">
                One of the tool I designed for our client GE Renewable as part
                of their core design team for 2.7 years.
              </p>
            </div>
            <img src={Gifanimation} className="w-full h-auto aspect-auto " />
          </div>
          <div className="w-full flex justify-between gap-[16px] items-start">
            <div className="flex flex-col w-full gap-[24px] ">
              <div className="flex flex-col gap-[8px] w-full">
                <div className="text-dh2 font-script text-text1 font-[400] ">
                  My Role
                </div>
                <p className="font-sans text-db text-text2 tracking-[-0.32px] leading-[150%] font-[400] ">
                  Designing end to end experience.
                </p>
              </div>

              <div className="flex flex-col gap-[8px] w-full">
                <div className="text-dh2 font-script text-text1 font-[400] ">
                  Team
                </div>

                <p className="font-sans text-db text-text2 tracking-[-0.32px] leading-[200%] font-[400] ">
                  Punit Gupta (Me) - Product Designer <br /> Ishani - Design
                  Lead <br />
                  Boniface - Design Manager <br /> Scott - Product Manager
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[8px] w-full">
              <div className="text-dh2 font-script text-text1 font-[400] ">
                Tasks Performed
              </div>

              <p className="font-sans text-db text-text2 tracking-[-0.32px] leading-[200%] font-[400] ">
                Requirement Gathering from Business and Users
                <br /> Synthesis using User Stories <br />
                Benchmarking & Competitive Analysis <br />
                Layout & Wireframe Designs <br />
                Visual & Interaction Design
                <br />
                Prototyping & Handoff
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[8px]">
            <div className="font-script text-dh2 text-text1 font-[400]">
              Background
            </div>
            <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
              <p>
                GE renewable is world’s largest renewable energy provider
                company. Wind energy is their one of the main business.
              </p>
              <p>
                Along with physical assets (e.g. turbine, solar plates,
                battery), GE also provide digital asset monitoring and analysis
                tool which is Lifespan.
              </p>
              <p>
                Engineers use lifespan to identify and resolve damages on
                turbine blades. This process is called blade inspection. It's a
                recurring procedure that happens monthly and as needed.
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              PROBLEM STATEMENT
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                How might we
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  Build intuitive blade image comparison experience in lifespan
                  (GE’s digital tool) to streamline and accelerate the damage
                  identification process.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[16px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                But...
              </div>
              <HighlightContainer
                title={"Why image comparison not anything else?"}
                body={
                  "Comparing current images with those from previous months helps engineers identify damages more quickly. This is standard industry practice."
                }
              />
              <HighlightContainer
                title={
                  "Is there any other way apart from comparison for damage identification ?"
                }
                body={
                  " Engineers also use AI, but currently there isn't enough data to train it properly. Once engineers begin doing comparisons within Lifespan, we'll have more data to make the AI smarter. "
                }
              />
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.ProblemIdentification}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              PROBLEM understanding
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Understanding problem from business
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  I get on a call with the business team first to understand the
                  problem, It was good 1:1 Q&A session between design and PM
                  team. I had the same question to them also
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[16px]">
              <HighlightList
                title={"Why do we need image comparison?"}
                list={[
                  "Standard practice throughout the industry, Having it will increase value of lifespan.",
                  " Resolution Time of damages is high, Image comparison will decrease that.",
                  "We have AI which helps engineers to mark damages, but it is not accurate, Image comparison       will also help us to make AI better by feeding right data.",
                ]}
              />
              <HighlightList
                title={
                  "What our users are doing right now?? is there any workaround in current workflow?? (BTW I love asking question 😅)"
                }
                list={[
                  "Currently, Lifespan allows users to perform inspections and make annotations, but lacks comparison functionality.",
                  "Some users rely solely on their expertise or only address severe damage alerts. However, fixing damages before they trigger alerts can help extend the lifespan of components and blades.",
                  "Some users are using third party tool like ziet view (guess we found competitor), but they are expensive and GE has to bear this extra cost.",
                  "Additionally, when creating a resolution case, engineers must transfer findings from third-party tools into Lifespan. During this transfer, most of the data is lost, forcing engineers to redo their annotations in Lifespan while cross-referencing with the third-party tool.",
                  "These third party tool are good with capturing images but not efficient with identifying damages.",
                ]}
              />
            </div>
          </div>

          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.TheBreakdown}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              THE BREAKDOWN
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                My next step was talking to users but then this happened 🥲🥲
              </div>
            </div>
            <div className="w-full italic  p-[16px] flex flex-col gap-[24px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
              <p className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                <span className="font-[700]">Me:</span> Hey Scott, Can we speak
                to the users also?? We really want to understand their side of
                story as well and we have some questions.
              </p>
              <p className="w-full font-sans  tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                <span className="font-[700]">Scott:</span> Hey Punit! Yes but It
                is very hard to get access to them as they usually work on the
                site. Can we put together a concept based on the current
                information before speaking to them. It would be great to have
                something to show while understanding their side of problem.
              </p>
              <p className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                <span className="font-[700]">Me:</span> Okay, I will try to put
                together something for the call.
              </p>
              <p className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                <span className="font-[700]">Scott:</span> Great! I will get
                access to some users meanwhile and setup a call soon…
              </p>
              <p className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                <span className="font-[700]">Me:</span> Sounds like a plan.{" "}
              </p>
            </div>
          </div>

          <div
            className="w-full flex flex-col gap-[60px]"
            ref={scrollRefs.ContextualEnquiry}
          >
            <div className="w-full flex flex-col gap-[24px]">
              <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
                contextual enquiry
              </div>
              <div className="font-script text-dh2 text-text1 font-[400]">
                Now to put together a concept for users, I needed 2 things
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <p className="font-[700]">1. Understanding of Workflow</p>
                  <p>
                    Where blade comparison take place in the larger damage
                    resolution process so that I can connect the dots for
                    concept. I mapped out the workflow of current damage
                    resolution process and sent it out for validation from PMs
                    and other subject experts.
                  </p>
                </div>
                <ImageWrapper
                  img={workflowImage}
                  caption={"Current inspection workflow"}
                />
                <HighlightList
                  title={"Insights"}
                  list={[
                    "Users can currently annotate blade images in Lifespan, but the comparison feature was missing. Image comparison will be integrated as a tool within the existing inspection process.",
                    "Current inspection tool can be used as base for image comparison.",
                    "There can be two types of annotations. 1. Added by engineers.  2. Added by AI",
                    "End goal behind these annotations is to create cases and task for resolution process",
                  ]}
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">
                  2. Understanding of current inspection (Internally called post
                  processing)
                </p>
                <p>
                  Now I wanted to understand, what lifespan is capable of doing
                  currently. So I looked into the current lifespan inspection
                  tools.
                </p>
              </div>
              <ImageWrapper
                img={currentIC}
                caption={"Blade inspection before"}
              />
              <HighlightList
                title={"Insights"}
                list={[
                  "Users can inspect each bade one by one.",
                  "A carousel is there to navigate through images.",
                  "Images in carousel are reflecting based on the selected location.",
                  "Common metadata for images :",
                  "Blade Serial Number (Unique to all the images of one blade) ",
                  "Selected Location →  Suction Side, Trailing Edge, Leading Edge, Pressure Side",
                  "Unique metadata for to each image: 1. Image Name, 2. Axis, 3.Blade Part Number, 4.Annotations  ",
                ]}
              />
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.HypothesisTesting}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Building hypothesis
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Building upon all of above information I derived below
                hypothesis and written down in the form of user stories.
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  My High level assumption was, Image comparison will work same
                  as existing inspection but side by side for two images.
                </p>
              </div>
              <ImageWrapper img={Hypothesis} caption={"Hypothesis"} />
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              first concept for hypothesis testing
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Mid fidelity concept design for user interview based on above
                hypothesis
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  I designed a concept and flow which I used in the conversation
                  with users. Since Lifespan already had blade inspection
                  workflow, I used that as basis to put together the concept.
                </p>
              </div>
              <ImageWrapper
                img={FirstConcept}
                caption={"First concept for user demo"}
              />
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  I showed these concept to users to validate my hypothesis and
                  asked more questions for clarification.
                </p>
              </div>
              <ImageWrapper
                img={InitialQuestions}
                caption={"Other questions asked from users"}
              />
            </div>
            <HighlightList
              title={"Insights"}
              list={[
                "Inspectors currently use Zeit View and occasionally Horizon for image comparison.",
                "Damages can be spread across the blade, they need a way to identify such cases.",
                "Navigating around the blade is one of the biggest ask.",
                "Users appreciate the location selection and z-index functionality offered by some tool’s blade illustration, but it is not organized well.",
                "Some tools lacks the ability to annotate the primary image during comparison which users find highly beneficial.",
                "Location selection of the current lifespan inspection is not intuitive, the dropdown changes the metadata instead of image.",
                "The term 'Side' does not adequately convey location; users suggest using 'Angle' instead.",
                "Blade serial number is not that important information.",
                "Working with these other tools creates challenges because they all use different terminology, making it difficult to understand and map information in Lifespan.",
              ]}
            />
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              redefining PROBLEM STATEMENT
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                How might we
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  Build intuitive blade image comparison experience in lifespan
                  (GE’s digital tool) tool and also{" "}
                  <span className="font-[700]">
                    {" "}
                    improve the current inspection process of lifespan.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.BenchmarkingStudy}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Benchmarking Study
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                The insights from users and business showed significant overlap,
                with both groups frequently mentioning ZeitView and Horizon.
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  To better understand their comparison features, I decided to
                  analyze these industry-standard tools in depth as benchmarks.
                </p>
              </div>
              <div className="font-sans pt-[16px]  text-db tracking-[-0.32px] leading-[150%] text-text1 font-[700]">
                Ziet View
              </div>
              <div className="w-full pt-[16px] pb-[24px] flex flex-col justify-start items-start gap-[8px]">
                <div className="p-[24px] flex flex-col gap-[24px] bg-background5 rounded rounded-[8px] border-[1px] border-stroke">
                  <img src={Z1} className="w-full h-auto aspect-auto" />
                  <img src={Z2} className="w-full h-auto aspect-auto" />
                </div>

                <div className="font-mono text-dc font-[400] text-text2">
                  Image comparison in ziet view
                </div>
              </div>
            </div>
            <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
              <div className="flex flex-col gap-[8px] w-full">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  Strengths
                </div>
                <ul className=" list-[square] list-outside pl-6 space-y-[12px]  w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2 ">
                  <li className=" pl-2">Shows metadata of older images</li>
                  <li className=" pl-2">
                    Allow users to sync comparison image to current image based
                    on date and z- location
                  </li>
                  <li className=" pl-2">Allows side by side comparison</li>
                </ul>
              </div>
              <div className="flex flex-col gap-[8px] w-full">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  Weakness
                </div>
                <ul className=" list-[square] list-outside pl-6 space-y-[12px]  w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2 ">
                  <li className=" pl-2">
                    No upfront carousel, Users have to go back to change image.
                  </li>
                  <li className=" pl-2">
                    Does not allow users to add annotation while comparing.
                  </li>
                  <li className=" pl-2">
                    Uses different nomenclature for annotation metadata
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-sans pt-[16px]  text-db tracking-[-0.32px] leading-[150%] text-text1 font-[700]">
                Horizon{" "}
              </div>
              <ImageWrapper
                img={Horizon}
                caption={"Damage resolution in horizon"}
              />
            </div>
            <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
              <div className="flex flex-col gap-[8px] w-full">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  Strengths
                </div>
                <ul className=" list-[square] list-outside pl-6 space-y-[12px]  w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2 ">
                  <li className=" pl-2">
                    Stitch image view, where users can see images based on
                    location
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-[8px] w-full">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  Weakness
                </div>
                <ul className=" list-[square] list-outside pl-6 space-y-[12px]  w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2 ">
                  <li className=" pl-2">Very confusing interface</li>
                  <li className=" pl-2">
                    arrows for navigation but no carousel
                  </li>
                  <li className=" pl-2">
                    Does not allow side by side comparison
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[60px]"
            ref={scrollRefs.Ideation}
          >
            <div className="w-full flex flex-col gap-[24px]">
              <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
                Ideations
              </div>
              <div className="font-script text-dh2 text-text1 font-[400]">
                The insights from these tools were helpful to start for the
                final users stories and ideation. We needed to decide two key
                things before moving to wireframes.{" "}
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <p className="font-[700]">1. What information to display</p>
                  <p>
                    After all the conversation and internal and market research,
                    It was time to synthesise all the information in categories
                    and user stories.
                  </p>
                </div>
                <div className="w-full pt-[16px] min-h-screen pb-[24px] flex flex-col justify-start items-start gap-[8px]">
                  <div className="p-[24px] h-auto w-full bg-background5 rounded rounded-[8px] border-[1px] border-stroke">
                    <iframe
                      allowFullScreen
                      style={{ border: "1px solid " }}
                      className="w-full min-h-screen"
                      src="https://embed.figma.com/board/hgrX4ydBMoFJeXRmyevrzC/GE-Lifespan-Map-View?node-id=28-2289&embed-host=share&footer=false&theme=dark"
                    ></iframe>
                  </div>
                  <div className="font-mono text-dc font-[400] text-text2">
                    User story mapping
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">
                  2. Where to display - Layout Design
                </p>
                <p>
                  With the help of categorisation of user stories, we were able
                  to decide what to show where in the layout. We divided the
                  whole layout in sections like.
                </p>
                <ul className="font-[700] list-[square] list-outside space-y-[8px] pl-4">
                  <li>Primary Navigation</li>
                  <li>Secondary Navigation for Images</li>
                  <li>Annotation Panel</li>
                  <li>Parameters and Image enhancer</li>
                  <li>Blade Navigators</li>
                </ul>
              </div>

              <ImageWrapper
                img={LayoutDesign}
                caption={"Layout of image comparison"}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-[60px]">
            <div className="w-full flex flex-col gap-[24px]">
              <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
                wireframe iterations
              </div>
              <div className="font-script text-dh2 text-text1 font-[400]">
                In all iterations, we focused on solving the major issues that
                users were most concerned about like.
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <ul className="font-[700] list-[square] list-outside space-y-[8px] pl-4">
                    <li>Easier navigating through images via carousel</li>
                    <li>Ability to compare images side by side</li>
                    <li>
                      Ability to check damages spread over the blade using blade
                      navigator
                    </li>
                    <li>Ability to add annotation while comparison</li>
                  </ul>
                </div>
                <ImageWrapper
                  img={WireframeIterations}
                  caption={"Wireframes"}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Feature Enhancement
            </div>
            <div className="font-script text-dh2 text-text1 font-[400]">
              Some other pain points which we considered while designing.
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">1. Better workflow status symbol</p>
                <p>
                  Current workflow status badges was matching with asset state
                  representation which makes engineers confuse. We gave it a new
                  representation.
                </p>
              </div>

              <ImageWrapper img={EnhancedSymbole} caption={"Better Symbols"} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">
                  2. Optimized header for post processing
                </p>
                <ul className=" list-[square] list-outside pl-6 space-y-[12px]  w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2 ">
                  <li className=" pl-2">Reduced the clutter from header. </li>
                  <li className=" pl-2">
                    Removed, videos dropdown as it was not useful.
                  </li>
                  <li className=" pl-2">
                    improved the hierarchy by emphasizing only important
                    elements.{" "}
                  </li>
                </ul>
              </div>

              <ImageWrapper img={EnhancedHeader} caption={"Optimized Header"} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">3. Expand / Collapse Carousel</p>
                <p>
                  We added a expanded view of carousel for better visibility to
                  images. While speaking to users we realize, These images can
                  look similar in small tiles. So having a bit larger view would
                  help users to identify desired image.
                </p>
              </div>

              <ImageWrapper img={EnhancedCarousel} caption={"Image Carousel"} />
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.VisualDesigns}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              final visual designs
            </div>

            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                We collated all the wireframes and potential improvements to the
                stakeholders for final validation. With small tweaks we all
                settled on final design.
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  For visuals we leveraged lifespan design system which we have
                  been maintaining since the beginning of the engagement with
                  GE.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Post Processing</p>
              </div>
              <ImageWrapper img={FinalUI1} caption={""} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]"> Expanded Blade View</p>
              </div>
              <ImageWrapper img={FinalUI2} caption={""} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]"> Image Comparison</p>
              </div>
              <ImageWrapper img={FinalUI3} caption={""} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]"> Annotations In Comparison </p>
              </div>
              <ImageWrapper img={FinalUI4} caption={""} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]"> Blade View with Carousel</p>
              </div>
              <ImageWrapper img={FinalUI5} caption={""} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]"> Carousel Expanded</p>
              </div>
              <ImageWrapper img={FinalUI6} caption={""} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]"> Inspection list</p>
              </div>
              <ImageWrapper img={FinalUI7} caption={""} />
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.Learnings}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Learnings & Way forward
            </div>
            <div className="font-script text-dh2 text-text1 font-[400]">
              Lessons, Insights & What I’d Do Differently
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <ul className="font-[400] list-[square] list-outside space-y-[8px] pl-4">
                  <li>
                    Talking to users with draft designs in hand is always give
                    better insights.
                  </li>
                  <li>
                    Stakeholders mostly talk from the business aspects but user
                    can give the real usability insights.
                  </li>
                  <li>
                    In the next versions, A 3D blade handle can become a helpful
                    tool in order to select blade axis and locations.
                  </li>
                  <li>
                    Reading fundamentals helps, There is no shame in becoming a
                    child and learn to get the basics right.{" "}
                  </li>
                  <li>
                    Elimination over Addition - Very helpful approach to make
                    the UI clean and intuitive.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PasswordProtectedPage>
  );
};
