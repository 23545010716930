import React, { useEffect, useState } from "react";
import "./index.css";
import "./App.css";

import { CaseStudyList } from "./components/layouts/CaseStudyList";

import {
  ImageComparison,
  ImageComparisonOld,
} from "./components/layouts/ImageComparison";
import { MyLibrary } from "./components/layouts/MyLibrary";

import { Loader } from "./components/ui/Loader";

import { Routes, Route, Router } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { Analytics } from "@vercel/analytics/react";
import { VisualDesign } from "./components/layouts/VisualDesign";

import Home1 from "./components/layouts/NewPages/Home1";
import AboutMe1 from "./components/layouts/NewPages/AboutMe1";
import { Footer1 } from "./components/layouts/NewPages/Footer1";
import { ImageComparison1 } from "./components/layouts/NewPages/ImageComparison1";
import { RealtimeMap } from "./components/layouts/NewPages/RealtimeMap";

function App() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="bg-background1 flex flex-col justify-center items-center">
        <Navbar />
        <Analytics />
        <div className="flex w-full justify-center align-center flex-col">
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Home1 />} />

            <Route
              path="/ImageComparison"
              element={<ImageComparison1 />}
            ></Route>
            <Route path="/RealtimeMap" element={<RealtimeMap />}></Route>

            <Route
              path="/ImageComparisonold"
              element={<ImageComparisonOld />}
            ></Route>

            <Route path="/AboutMe" element={<AboutMe1 />}></Route>
            <Route path="/MyLibrary" element={<MyLibrary />}></Route>
            <Route path="/VisualDesign" element={<VisualDesign />}></Route>
          </Routes>
        </div>
        {/* <Divider /> */}
        <Footer1 />
      </div>
    </>
  );
}

export default App;
