import React, { useEffect } from "react";
import { Star2 } from "../../../Icons/Stars";
import { useAnimationControls, motion } from "framer-motion";

export const MarqueeText = ({ texts, bgColor }) => {
  // Duplicate the array **only once** for smooth looping
  const repeatArray = [...texts, ...texts];

  const controls = useAnimationControls();
  useEffect(() => {
    controls.start({
      x: "-50%", // Shift only half so it seamlessly loops
      transition: {
        duration: 20, // Adjust for smooth speed
        ease: "linear",
        repeat: Infinity,
      },
    });
  }, [controls]);

  return (
    <div className="relative w-full overflow-hidden py-10">
      <motion.div
        className="inline-flex whitespace-nowrap gap-[24px] items-center py-[16px]  text-foreground1 font-sans uppercase font-[700] text-body"
        animate={controls}
        style={{ backgroundColor: bgColor }}
      >
        {repeatArray.map((src, index) => (
          <div key={index} className="min-w-max">
            {src}
          </div>
        ))}
      </motion.div>
    </div>
  );
};
