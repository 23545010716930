"use client";
import { motion, useScroll, useTransform } from "framer-motion";

export const FloatingWrapper = ({ children }) => {
  const { scrollYProgress } = useScroll();

  // Create a smooth floating effect using Framer Motion
  const floatAnimation = {
    y: ["0%", "-10%", "0%"], // Moves up and down
    transition: {
      duration: 3,
      repeat: Infinity,
      repeatType: "mirror",
      ease: "easeInOut",
    },
  };

  // Parallax effect (moves slightly as user scrolls)
  const parallaxY = useTransform(scrollYProgress, [0, 1], [0, -50]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <motion.div animate={floatAnimation} className="relative inline-block">
        {children}
      </motion.div>
    </motion.div>
  );
};
