import {
  animate,
  AnimatePresence,
  motion,
  useScroll,
  useSpring,
} from "framer-motion";
import { useTransform } from "framer-motion";
import React, { useEffect, useState } from "react";
import { IconShadowButton } from "../Buttons";
import {
  HomeIcon,
  ScrollIcon,
} from "../layouts/NewComponents/ProjectCardIcons";
import { useNavigate } from "react-router-dom";

export const FloatingNavigator = ({ scrollRefs, handleScroll }) => {
  const [open, setOpen] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const formatCamelCase = (text) => {
    return text.replace(/([A-Z])/g, " $1").trim();
  };

  const { scrollYProgress } = useScroll();
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const scrollYPercentage = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const ScaleY = useSpring(scrollYProgress, {
    stiffness: 150,
    damping: 30,
    restDelta: 0.001,
  });

  useEffect(() => {
    scrollYPercentage.on("change", (latest) => {
      setScrollPercentage(Math.floor(latest)); // Set to two decimal places
    });
  }, [scrollYPercentage]);
  const navigation = useNavigate();

  const playClickSound = () => {
    const audio = new Audio("/WhoopSound.mp3");
    audio.play();
  };

  return (
    <div className=" flex items-center justify-center ">
      <motion.div
        layout
        initial={{
          height: 50,
          scale: 1,
        }}
        animate={{
          height: open === true ? 450 : 50,
          scale: open === true ? 1.2 : 1,
          transition: {
            type: "spring",
            stiffness: 150,
            bounce: 500,

            damping: 15,
          },
        }}
        exit={{
          height: 50,
          scale: 1,
        }}
        className={` flex  h-[900px] flex-col items-left justify-center gap-[12px] cursor-pointer  z-30 border-[1px] border-accentColor bg-background1 shadow-[0px_16px_32px_rgba(12,12,13,0.10),0px_4px_4px_rgba(12,12,13,0.1)] ${
          open ? "px-[16px]  w-[250px]" : " px-[6px] py-[6px]"
        } rounded rounded-[16px] fixed bottom-[50px] `}
        onClick={() => {
          playClickSound();
          setOpen(!open);
        }}
      >
        <div
          className="flex flex-row w-[fit]  gap-[80px] justify-between items-center"
          style={{ width: "-webkit-fill-available" }}
        >
          <div className="flex flex-row gap-[4px] items-center justify-center">
            <svg
              id="progress"
              width="32"
              height="32"
              viewBox="0,0,32,32"
              style={{ transform: "rotate(-90deg)" }}
            >
              <motion.circle
                cx="16"
                cy="16"
                r="12"
                fill="none"
                pathLength="1"
                stroke="#EEEBF1"
                stroke-width="5"
              />
              <motion.circle
                cx="16"
                cy="16"
                r="12"
                fill="none"
                stroke="var(--accent)"
                stroke-width="5"
                strokeLinecap="round"
                pathLength="1"
                style={{ pathLength: scrollYProgress }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 30,
                  restDelta: 0.001,
                }}
              />
            </svg>
            <div
              onClick={() => {
                setOpen(!open);
              }}
              className="text-[16px] font-bold"
            >
              Index
            </div>
          </div>
          <div
            className={`rounded rounded-full font-serif text-[12px] font-[700]  w-auto h-[34px] px-[8px] py-[4px] 
         flex items-center justify-center bg-[#EDECEC]`}
          >
            {scrollPercentage}%
          </div>
        </div>
        <AnimatePresence>
          {open && (
            <motion.div className=" overflow h-auto  w-full flex flex-col items-left justify-center gap-[16px]">
              {Object.entries(scrollRefs).map(([key, ref, index]) => (
                <div
                  key={key}
                  onClick={() => {
                    handleScroll(ref);
                    setOpen(false);
                  }}
                  className="font-sans tracking-[-0.32px] font-[400] leading-[150%] text-[12px] px-[4px] py-[2px] w-full cursor-pointer hover:text-accentColor hover:bg-background2"
                >
                  {index} {formatCamelCase(key)}{" "}
                  {/* Displays section name dynamically */}
                </div>
              ))}

              <div className="flex items-left justify-left gap-[16px]">
                <IconShadowButton
                  icon={<ScrollIcon />}
                  action={() => {
                    scrollToTop();
                    setOpen(false);
                  }}
                />
                <IconShadowButton
                  icon={<HomeIcon />}
                  action={() => {
                    navigation("/");
                  }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      {open && (
        <div
          onClick={() => {
            setOpen(false);
          }}
          className="bg-[#00] z-20  fixed  h-[100vh] w-[100vw] inset-y-0 inset-x-0 opacity-[0.8]"
        />
      )}
    </div>
  );
};
