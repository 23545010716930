import React from "react";

import { motion } from "framer-motion";

export const Star1 = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 16H6V12.8287L3.75951 15.0692L0.931083 12.2408L3.17188 10H0V6H2.90806L0.778143 4.02188L3.5002 1.09094L6 3.41258V0H10V3.17188L12.2448 0.927083L15.0732 3.75551L12.8287 6H16V10H13.0929L15.224 11.9792L12.5019 14.9101L10 12.5865V16Z"
        fill="black"
      />
    </svg>
  );
};

export const Star2 = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.5L11.1998 4.46817L13.8268 1.2612L13.4168 5.38646L17.0711 3.42893L15.1135 7.08324L19.2388 6.67317L16.0318 9.30019L20 10.5L16.0318 11.6998L19.2388 14.3268L15.1135 13.9168L17.0711 17.5711L13.4168 15.6135L13.8268 19.7388L11.1998 16.5318L10 20.5L8.80019 16.5318L6.17317 19.7388L6.58324 15.6135L2.92893 17.5711L4.88646 13.9168L0.761205 14.3268L3.96817 11.6998L0 10.5L3.96817 9.30019L0.761205 6.67317L4.88646 7.08324L2.92893 3.42893L6.58324 5.38646L6.17317 1.2612L8.80019 4.46817L10 0.5Z"
        fill="black"
      />
    </svg>
  );
};

export const Star3 = () => {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0.367188L19.0037 19.3485L26.271 1.81347L19.0106 19.3514L32.435 5.93217L19.0158 19.3566L36.5537 12.0962L19.0187 19.3635L38 19.3672L19.0187 19.3709L36.5537 26.6382L19.0158 19.3778L32.435 32.8022L19.0106 19.383L26.271 36.9209L19.0037 19.3859L19 38.3672L18.9963 19.3859L11.729 36.9209L18.9894 19.383L5.56498 32.8022L18.9842 19.3778L1.44628 26.6382L18.9813 19.3709L0 19.3672L18.9813 19.3635L1.44628 12.0962L18.9842 19.3566L5.56498 5.93217L18.9894 19.3514L11.729 1.81347L18.9963 19.3485L19 0.367188Z"
        stroke="black"
        stroke-width="4"
      />
    </svg>
  );
};

export const RotatingStar = () => {
  return (
    <motion.svg
      width="58"
      height="59"
      viewBox="0 0 58 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      animate={{ rotate: 360 }} // Rotates full circle
      transition={{ repeat: Infinity, duration: 10, ease: "linear" }}
    >
      <path
        d="M29 0.078125L29.0056 29.0496L40.0979 2.28561L29.0161 29.054L49.506 8.57204L29.0241 29.062L55.7925 17.9802L29.0285 29.0725L58 29.0781L29.0285 29.0837L55.7925 40.176L29.0241 29.0943L49.506 49.5842L29.0161 29.1022L40.0979 55.8706L29.0056 29.1067L29 58.0781L28.9944 29.1067L17.9021 55.8706L28.9839 29.1022L8.49391 49.5842L28.9759 29.0943L2.20748 40.176L28.9715 29.0837L0 29.0781L28.9715 29.0725L2.20748 17.9802L28.9759 29.062L8.49391 8.57204L28.9839 29.054L17.9021 2.28561L28.9944 29.0496L29 0.078125Z"
        stroke="black"
        stroke-width="4"
      />
    </motion.svg>
  );
};
