import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { useState, useEffect } from "react";
import useFetchImages from "../../utils/FetchImages";

const extractTitleFromUrl = (url) => {
  try {
    const decodedUrl = decodeURIComponent(url);
    const fileName = decodedUrl.split("/").pop();
    if (!fileName) return "Image";
    const nameWithoutExtension = fileName.split(".")[0];
    if (nameWithoutExtension.toLowerCase().startsWith("img")) {
      const number = nameWithoutExtension.replace(/\D/g, "");
      return `Image ${number}`;
    }
    return nameWithoutExtension;
  } catch (error) {
    return "Image";
  }
};

// Image component with loading state
const LazyImage = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    // Reset loading state when src changes
    setIsLoading(true);
    setImageSrc("");

    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
      setIsLoading(false);
    };

    return () => {
      img.onload = null;
    };
  }, [src]);

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 bg-[#0E0F10] animate-pulse rounded" />
      )}
      {imageSrc && (
        <img
          src={imageSrc}
          alt={alt}
          className={`${className} object-contain w-full h-full ${
            isLoading ? "opacity-10" : "opacity-100"
          } transition-opacity duration-300`}
        />
      )}
    </>
  );
};

// Column component with virtualization
const ImageColumn = ({ images, smoothY, width }) => {
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 6 });

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;
      const startIndex = Math.max(
        0,
        Math.floor((scrollY - viewportHeight) / 352) - 1
      );
      const endIndex = Math.min(
        images.length,
        Math.ceil((scrollY + viewportHeight * 2) / 352) + 1
      );

      setVisibleRange({ start: startIndex, end: endIndex });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, [images.length]);

  return (
    <motion.div
      style={{ y: smoothY }}
      className={`${width} border-l border-white/[0.15] flex flex-col `}
    >
      {images.map((url, i) => {
        // Only render images within the visible range
        if (i < visibleRange.start || i > visibleRange.end) {
          return <div key={i} className="h-[352px]" />;
        }

        return (
          <div
            key={i}
            className="relative aspect-w-4 aspect-h-3 justify-center items-center overflow-hidden "
          >
            <h3
              className="absolute top-0 left-0 p-2 text-white text-xs z-10 "
              style={{ fontFamily: "MonaSans" }}
            >
              {extractTitleFromUrl(url)}
            </h3>
            <LazyImage
              src={url}
              alt={`Column Image ${i + 1}`}
              className="w-full  "
            />
          </div>
        );
      })}
    </motion.div>
  );
};

export const VisualDesign = () => {
  const { scrollY } = useScroll();

  const slowestY = useTransform(scrollY, [0, window.innerHeight], [0, -100]);
  const slowerY = useTransform(scrollY, [0, window.innerHeight], [0, -200]);
  const normalY = useTransform(scrollY, [0, window.innerHeight], [0, -300]);

  const smoothSlowestY = useSpring(slowestY, { stiffness: 100, damping: 20 });
  const smoothSlowerY = useSpring(slowerY, { stiffness: 100, damping: 20 });
  const smoothNormalY = useSpring(normalY, { stiffness: 100, damping: 20 });

  const column1Images = useFetchImages("visualDesign/column1");
  const column2Images = useFetchImages("visualDesign/column2");
  const column3Images = useFetchImages("visualDesign/column3");

  return (
    <div className="relative min-h-screen">
      <div className="sticky top-0 flex gap-0 mx-[5vw] pt-[15vh]">
        <ImageColumn
          images={column1Images}
          smoothY={smoothSlowestY}
          width="w-[22vw]"
        />
        <ImageColumn
          images={column2Images}
          smoothY={smoothSlowerY}
          width="w-[32vw]"
        />
        <ImageColumn
          images={column3Images}
          smoothY={smoothNormalY}
          width="w-[40vw]"
        />
      </div>
    </div>
  );
};
