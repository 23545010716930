import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const SmileyFace = ({ distance }) => {
  // Determine expression based on distance
  const isHappy = distance < 170; // Adjust threshold as needed

  // Handle eye movement
  const [eyePosition, setEyePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    setEyePosition({
      x: (clientX - centerX) / 500,
      y: (clientY - centerY) / 500,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <motion.svg
      width="125"
      height="124"
      viewBox="0 0 125 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M69.6318 3.89738L66.7518 13.2454C65.3988 17.6354 59.1978 17.6684 57.7978 13.2934L54.8168 3.97738C53.0828 -1.44162 45.0068 0.320379 45.6878 5.96938L46.8588 15.6804C47.4088 20.2404 41.7568 22.7934 38.6978 19.3664L32.1848 12.0684C28.3958 7.82338 21.7358 12.7174 24.6558 17.6014L29.6748 25.9964C32.0318 29.9384 27.9068 34.5704 23.7188 32.6834L14.8008 28.6654C9.6128 26.3284 5.5188 33.5084 10.1728 36.7824L18.1728 42.4104C21.9298 45.0534 20.0448 50.9624 15.4518 50.9414L5.6708 50.8974C-0.0192032 50.8724 -0.839195 59.0964 4.7448 60.1954L14.3418 62.0834C18.8488 62.9694 19.5308 69.1344 15.3258 70.9834L6.3728 74.9224C1.16479 77.2134 3.76081 85.0604 9.30781 83.7934L18.8428 81.6144C23.3208 80.5914 26.4508 85.9454 23.3618 89.3444L16.7848 96.5844C12.9588 100.796 18.5228 106.908 23.0738 103.494L30.8988 97.6254C34.5738 94.8694 39.6108 98.4874 38.1718 102.849L35.1078 112.138C33.3258 117.542 40.8948 120.863 43.6638 115.892L48.4248 107.348C50.6608 103.336 56.7338 104.592 57.1938 109.162L58.1728 118.894C58.7428 124.555 67.0078 124.511 67.5158 118.843L68.3898 109.101C68.7998 104.526 74.8598 103.204 77.1388 107.192L81.9918 115.684C84.8148 120.624 92.3478 117.222 90.5068 111.838L87.3428 102.583C85.8568 98.2374 90.8548 94.5644 94.5588 97.2814L102.447 103.065C107.035 106.43 112.533 100.258 108.661 96.0884L102.006 88.9204C98.8808 85.5544 101.953 80.1664 106.441 81.1414L115.999 83.2174C121.559 84.4254 124.071 76.5504 118.838 74.3154L109.843 70.4734C105.619 68.6694 106.234 62.4984 110.731 61.5634L120.307 59.5724C125.878 58.4144 124.969 50.1984 119.28 50.2854L109.5 50.4344C104.907 50.5044 102.959 44.6164 106.687 41.9334L114.626 36.2194C119.244 32.8954 115.073 25.7594 109.911 28.1534L101.037 32.2674C96.8698 34.1994 92.6948 29.6134 95.0088 25.6454L99.9388 17.1954C102.806 12.2804 96.0928 7.45838 92.3498 11.7444L85.9168 19.1124C82.8958 22.5724 77.2168 20.0804 77.7168 15.5144L78.7828 5.79139C79.4018 0.134385 71.3078 -1.53962 69.6318 3.89738Z"
        fill="#FEE984"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <motion.path
        d="M36.6305 82.003C25.1067 67.6814 27.3747 46.7296 41.6963 35.2057C56.0179 23.6819 76.9698 25.9499 88.4936 40.2716C100.017 54.5932 97.7494 75.545 83.4278 87.0689C69.1062 98.5927 48.1543 96.3247 36.6305 82.003Z"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
        animate={{ fill: isHappy ? "#C2FC9E" : "#FBA783" }}
      />
      <path
        d="M67.9465 54.3783C66.8785 59.5423 68.4875 64.2413 71.5415 64.8733C74.5955 65.5053 77.9375 61.8313 79.0055 56.6673C80.0745 51.5033 78.4645 46.8043 75.4105 46.1723C72.3575 45.5403 69.0155 49.2143 67.9465 54.3783Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx={72 + eyePosition.x}
        cy={54 + eyePosition.y}
        r="5"
        fill="black"
      />
      <path
        d="M49.2433 50.5111C48.1743 55.6751 49.7843 60.3741 52.8383 61.0061C55.8923 61.6381 59.2343 57.9641 60.3023 52.8001C61.3713 47.6361 59.7613 42.9371 56.7073 42.3051C53.6533 41.6731 50.3113 45.3471 49.2433 50.5111Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx={54 + eyePosition.x}
        cy={50 + eyePosition.y}
        r="5"
        fill="black"
      />
      <motion.path
        d={
          isHappy
            ? "M48 65 Q60 80 73 70" // Smile
            : "M48 75 Q60 60 73 70" // Sad
        }
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        fill="none"
        animate={{ d: isHappy ? "M48 65 Q60 80 73 70" : "M48 75 Q60 60 73 70" }}
      />
    </motion.svg>
  );
};

export default SmileyFace;
