import React from "react";
import { HeroSection1 } from "./HeroSection1";
import { Coding, GamingController, Music } from "../../../Icons/HeroIcons";
import TooltipWrapper from "../NewComponents/HoverWrapper";
import { FloatingWrapper } from "../NewComponents/FloatingWrapper";
import { MarqueeText } from "../NewComponents/MarqueeText";
import CardGrid from "../NewComponents/NewProjectsCards";
import FloatingTooltip from "../NewComponents/HoverWrapper";
import { CuratedUIWall } from "./CuratedUIWall";
import { Footer1 } from "./Footer1";

const Home1 = () => {
  return (
    <div className=" grid grid-cols-12  gap-[16px] h-[auto] mx-[48px] max-w-[1470px] items-center justify-center bg-background1 ">
      <div className=" col-span-8 col-start-3 flex items-center justify-center">
        <div className="h-screen">
          <HeroSection1 />
        </div>
      </div>

      <div className=" col-span-12 col-start-1 ">
        <div className="w-[100%] font-serif items-baseline text-foreground1 text-[20px] font-[900] pt-[120px] pb-[60px]">
          Selected
          <span className="text-[32px] pl-[4px] font-[400] italic font-script text-foreground1">
            Case studies
          </span>{" "}
        </div>
        <CardGrid />
      </div>
      {/* <div className=" col-span-12 col-start-1 mt-[16px]">
        <div className="w-[100%] font-serif text-foreground1 text-[20px] font-[700] pt-[120px] pb-[60px]">
          Selected
          <span className="text-[32px] pl-[4px] font-[400] italic font-script text-foreground1">
            Visual designs
          </span>
        </div>

        <CuratedUIWall />
      </div> */}
    </div>
  );
};
export default Home1;
