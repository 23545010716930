import AnimatedFlask from "../Icons/Flask";

import { NavbarContainer, MenuButton } from "../styles/Navbar.styled";
import { fadeInBottomVarient, fadeInTopVarient } from "../utils/Variants";
import { ButtonV2, ButtonV3, ShadowButton } from "./Buttons";

import { ThemeSwitcher } from "./Buttons";
import { NavMenu } from "./layouts/NavMenu";
import { WarningStrip } from "./layouts/WarningStrip";
import { motion, AnimatePresence, useScroll, useSpring } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const Navbar = (props) => {
  const location = useLocation();
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 150,
    damping: 30,
    restDelta: 0.001,
  });
  const navigation = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [sticky, setSticky] = useState(false);
  console.log("props", props);
  const playClickSound = () => {
    const audio = new Audio("/ClickSound.mp3");
    audio.play();
  };

  return (
    <div>
      <div className="flex flex-col fixed left-0  bg-background1 shadow-sm  backdrop-blur-[15px] h-auto pt-[2px] pb-[4px] w-[100%] top-0 z-[99]  overflow-hidden  sm:overflow-hidden sm:h-[auto] max-w-[1470px]   sm:flex-col sm:bg-background sm:py-5">
        <AnimatePresence>
          {openMenu && <NavMenu setOpenMenu={setOpenMenu} />}
        </AnimatePresence>

        <div className=" flex flex-row justify-between w-[100%] h-[80px] items-center px-[5vw] sm:px-[1rem]  sm:flex-row sm:h-[30px]  ">
          <div className="hidden sm:flex">
            {openMenu ? (
              <ButtonV2 label={"Menu"} action={() => setOpenMenu(false)} />
            ) : (
              <ButtonV2 label={"Menu"} action={() => setOpenMenu(true)} />
            )}
          </div>

          <div className="flex flex-row text-[14px] justify-between items-center bg-blue- w-[100%] sm:hidden py-[8px] ">
            <div
              onClick={() => {
                playClickSound();

                navigation("/");
              }}
              className=" flex items-center text-label cursor-pointer font-mono text-foreground1 font-d1 justify-center w-[auto]"
            >
              Punit Gupta
            </div>
            <div className="flex flex-row gap-[24px] bg-background1 border-[1px] border-stroke p-[6px] rounded rounded-[8px]">
              <div
                to="/"
                className={`${
                  location.pathname === "/"
                    ? " flex px-[12px] py-[8px] items-center text-label font-mono bg-[#5E2BFF30] text-accentColor rounded rounded-[4px] font-d1 justify-center w-[auto]  cursor-pointer "
                    : "flex items-center px-[12px] py-[8px] text-label font-mono  text-foreground2  justify-center w-[auto] cursor-pointer hover:text-accentColor  "
                }`}
                label={"CaseStudies"}
                onClick={() => {
                  playClickSound();

                  navigation("/");
                }}
              >
                [Home]
              </div>
              {/* <div
              to="/casestudies"
              className={`${
                location.pathname === "/CaseStudies"
                  ? " flex items-center text-label font-sans text-accentColor font-d1 justify-center w-[auto]  cursor-pointer "
                  : "flex items-center text-label font-sans  text-foreground2  justify-center w-[auto] cursor-pointer hover:text-accentColor  "
              }`}
              label={"CaseStudies"}
              onClick={() => navigation("/CaseStudies")}
            >
              [Case Studies]
            </div> */}

              {/* <div
              to="/VisualDesign"
              className={`${
                location.pathname === "/VisualDesign"
                  ? " flex items-center text-label font-sans text-accentColor font-d1 justify-center w-[auto]  cursor-pointer "
                  : "flex items-center text-label font-sans  text-foreground2  justify-center w-[auto] cursor-pointer hover:text-accentColor"
              }`}
              label={"Visual Design"}
              onClick={() => navigation("/VisualDesign")}
            >
              [Visuals]
            </div> */}

              {/* <div
              to="/lab"
              className={`${
                location.pathname === "/MyLibrary"
                  ? " flex items-center text-label font-sans text-accentColor font-d1 justify-center w-[auto]  cursor-pointer "
                  : "flex items-center text-label font-sans  text-foreground2  justify-center w-[auto] cursor-pointer hover:text-accentColor  "
              }`}
              label={"Lab"}
              onClick={() => navigation("/MyLibrary")}
            >
              [Interactions]
            </div> */}
              <div
                to="/aboutme"
                className={`${
                  location.pathname === "/aboutme"
                    ? " flex items-center px-[12px] py-[8px] bg-[#5E2BFF30] text-accentColor text-label rounded rounded-[2px] font-mono text-accentColor font-d1 justify-center w-[auto]  cursor-pointer "
                    : "flex items-center px-[12px] py-[8px] text-label font-mono  text-foreground2  justify-center w-[auto] cursor-pointer hover:text-accentColor  "
                }`}
                label={"About Me"}
                onClick={() => {
                  playClickSound();
                  navigation("/aboutme");
                }}
              >
                [About Me]
              </div>
            </div>

            {/* <div className=" w-[1px] h-[40px] bg-background3"> </div> */}

            <div
              className="pb-[10px]"
              onClick={() => {
                playClickSound();
                window.open("resume.pdf");
              }}
            >
              <ShadowButton label={"[Resume]"} />
            </div>
          </div>
        </div>
      </div>
      {/* <WarningStrip /> */}
    </div>
  );
};
