import { ButtonV2, ButtonV3, ShadowButton } from "./Buttons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "../styles/CaseStudyDetail.styled";
import eyeAnimation from "../assets/eye.json";
import Lottie, { LottiePlayer } from "lottie-react";

const typeSound = new Audio("/TypeSound.mp3");
const backSpaceSound = new Audio("/BackSpace.mp3");
const errorSound = new Audio("/Error.mp3");

const PasswordModal = ({ onCorrectPassword }) => {
  const openEmailClient = () => {
    window.location.href = "mailto:";
  };

  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { value } = e.target;
    const isBackspace = e.nativeEvent.inputType === "deleteContentBackward";

    setPassword(value);

    if (isBackspace) {
      backSpaceSound.volume = 0.2;
      backSpaceSound.currentTime = 0;
      backSpaceSound.play(); // Play backspace sound
    } else {
      typeSound.currentTime = 0;
      typeSound.play(); // Play typing sound for normal keys
    }
  };

  const playTypeSound = () => {
    typeSound.currentTime = 0; // Restart sound on each keypress
    typeSound.play();
  };
  const playErrorSound = () => {
    errorSound.currentTime = 0; // Restart sound
    errorSound.play();
  };

  const handleSubmit = (e) => {
    playClickSound();
    e.preventDefault();
    if (password === "P@ssw0rd") {
      onCorrectPassword();
    } else {
      setError(" Haha...Nice Try!");
      playErrorSound();
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  const playClickSound = () => {
    const audio = new Audio("/ClickSound.mp3");
    audio.play();
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-accentColor">
      {/* <div className="absolute inset-0  opacity-50 backdrop-filter backdrop-blur-lg"></div> */}
      <div className=" flex flex-col content-between z-50 bg-background1 w-[40vw] h-[auto] p-[24px] rounded-lg shadow-xl">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-between h-[auto] gap-[20px]"
        >
          <div className="flex flex-col gap-[8px] justify-start align-start">
            <span className=" h-[50px] items-center relative inline-flex  px-[4px] border-[1px] border-[#4199F8]">
              <Title> Enter the password to take a peek.</Title>
              <div className="absolute inset-0 flex justify-between items-center pointer-events-none">
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] left-[-4px]"></div>
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] right-[-4px]"></div>
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] left-[-4px]"></div>
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] right-[-4px]"></div>
              </div>
            </span>
            <div className="text-foreground1 items-left font-sans tracking-[-0.64px] font-body leading-[160%] inline-block  text-left">
              I wish I could spill all the design secrets… but NDA says nope! 🔒{" "}
              <div className="w-[24px] h-[24px]">
                <Lottie
                  animationData={eyeAnimation}
                  loop={true}
                  width="56px"
                  height="56px"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <div className="flex flex-col gap-[0rem] ">
              <input
                type="password"
                value={password}
                onChange={handleChange}
                className="w-full bg-background2 p-4 border border-accentColor rounded "
                placeholder="type here..."
              />
              {error && (
                <p className="text-red-600 items-left font-sans tracking-[-0.64px] font-body leading-[160%]   text-left">
                  {error}
                </p>
              )}
            </div>
            <div className="flex flex-row-reverse justify-between mt-[20px] pt-[8px] h-auto     ">
              <div className="flex gap-[24px]">
                <div
                  className="flex items-center text-label font-mono  text-foreground2  justify-center w-[auto] cursor-pointer hover:text-accentColor"
                  onClick={handleCancel}
                >
                  {" "}
                  [Cancel]{" "}
                </div>
                <div onClick={handleSubmit}>
                  <ShadowButton label={"SUBMIT"} type="submit" />
                </div>
              </div>
              <div
                className="flex items-center text-label font-mono  text-foreground2  justify-center w-[auto] cursor-pointer hover:text-accentColor"
                onClick={() => openEmailClient()}
              >
                {" "}
                [Get Password]{" "}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const PasswordProtectedPage = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleCorrectPassword = () => {
    setIsAuthenticated(true);
  };

  return (
    <>
      {!isAuthenticated && (
        <PasswordModal onCorrectPassword={handleCorrectPassword} />
      )}
      {isAuthenticated && children}
    </>
  );
};

export default PasswordProtectedPage;
