import React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export const ImageWrapper = ({ img, caption }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="w-full pt-[16px] pb-[24px] flex flex-col justify-start items-start gap-[8px]">
        <div
          className="p-[24px] bg-background5 rounded rounded-[8px] border-[1px] border-stroke"
          onClick={() => setIsOpen(true)}
        >
          <img src={img} className="w-full h-auto aspect-auto" />
        </div>
        <div className="font-mono text-dc font-[400] text-text2">
          {" "}
          {caption}{" "}
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 bg-[#F3F3F3] bg-opacity-100 flex justify-center items-center z-[100]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {/* Image Container */}
            <div className="relative">
              {/* Close Button */}
              <button
                className="absolute top-4 right-4 bg-black text-white px-3 py-1 rounded-full text-xl font-bold"
                onClick={() => setIsOpen(false)}
              >
                ✕
              </button>

              {/* Enlarged Image */}
              <img
                src={img}
                className="max-w-[90vw] max-h-[90vh] rounded-lg"
                alt="enlarged"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export const HighlightContainer = ({ title, body }) => {
  return (
    <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
      <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
        {title}
      </div>
      <div className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
        {body}
      </div>
    </div>
  );
};

export const HighlightList = ({ title, list }) => {
  return (
    <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
      <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
        {title}
      </div>

      <ul className=" list-[square] list-outside pl-6 space-y-[12px]  w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2 ">
        {list.map((item, index) => (
          <li className=" pl-2">{item}</li>
        ))}
      </ul>
    </div>
  );
};
