import React from "react";
import { useRef } from "react";
import { FloatingNavigator } from "../../ui/FloatingNavigator";
import Lottie from "lottie-react";
import HeroAnimation from "../../../assets/Map-_-Resources-Layer.json";
import {
  HighlightContainer,
  HighlightList,
  ImageWrapper,
} from "../NewComponents/CaseStudyComps";
import Hypothesis from "../../../assets/RealtimeMap/Hypothesis.webp";
import POC1 from "../../../assets/RealtimeMap/POC1.webp";
import POC2 from "../../../assets/RealtimeMap/POC2.webp";
import POC3 from "../../../assets/RealtimeMap/POC3.webp";
import POC4 from "../../../assets/RealtimeMap/POC4.webp";
import UserDemo from "../../../assets/RealtimeMap/UserDemo.webp";
import PlanningWorkflow from "../../../assets/RealtimeMap/PlanningWorkflow.webp";
import ExecutionWorkflow from "../../../assets/RealtimeMap/ExecutionWorkflow.webp";
import BenchmarkStudy from "../../../assets/RealtimeMap/BenchmarkStudy.webp";
import userStories from "../../../assets/RealtimeMap/UserStories.webp";
import Wireframe1 from "../../../assets/RealtimeMap/Wireframe1.webp";
import Wireframe2 from "../../../assets/RealtimeMap/Wireframe2.webp";
import LeftControls from "../../../assets/RealtimeMap/LeftControls.webp";
import RightPanel from "../../../assets/RealtimeMap/RightPanel.webp";

import VesselCard from "../../../assets/RealtimeMap/VesselHovercard.webp";
import AssetCard from "../../../assets/RealtimeMap/assetHoverCard.webp";

import Wireframe3 from "../../../assets/RealtimeMap/Wireframe3.webp";

import Wireframe4 from "../../../assets/RealtimeMap/wireframe4.webp";
import MapVisual1 from "../../../assets/RealtimeMap/MapVisual1.webp";
import MapVisual2 from "../../../assets/RealtimeMap/MapVisual2.webp";

import MapVisual3 from "../../../assets/RealtimeMap/MapVisual3.webp";
import MapVisual4 from "../../../assets/RealtimeMap/MapVisual4.webp";
import MapVisual5 from "../../../assets/RealtimeMap/MapVisual5.webp";
import MapVisual6 from "../../../assets/RealtimeMap/MapVisual6.webp";
import PasswordProtectedPage from "../../PasswordModal";

export const RealtimeMap = () => {
  const scrollRefs = {
    ProblemStatement: useRef(null),
    POCDesign: useRef(null),
    DetailedRequirementGathering: useRef(null),
    WorkflowMapping: useRef(null),
    BenchmarkingStudy: useRef(null),
    EpicLevelStories: useRef(null),
    Ideation: useRef(null),
    InteractionAndVisualDesigns: useRef(null),
    Learnings: useRef(null),

    // Add refs for all sections you need
  };
  const handleScroll = (section) => {
    if (section && section.current) {
      const headerOffset = 80; // Adjust this value based on your header height
      const elementPosition =
        section.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      console.error("Section is undefined or current is null:", section);
    }
  };
  return (
    <PasswordProtectedPage>
      <div className="grid grid-cols-12  mt-[120px] gap-[16px] h-[auto] mx-[48px] max-w-[1470px] ]">
        <div className="col-span-2 col-start-11">
          <FloatingNavigator
            scrollRefs={scrollRefs}
            handleScroll={handleScroll}
          />
        </div>
        <div className="col-span-6 col-start-4 flex-col flex gap-[120px]">
          <div className="w-full flex flex-col gap-[24px]  ">
            <div className="w-full flex flex-col gap-[16px]">
              <p className="text-dh1 text-text1 font-[700] font-script">
                GE REN - Realtime map for planning & monitoring
              </p>
              <p className="font-sans text-db text-text2 tracking-[-0.3px] leading-[150%] font-[400] ">
                GE renewable is one of the largest energy solution provider in
                the world. They deal in multiple type of energy solutions but
                wind energy is there biggest source of business.
              </p>
              <p className="font-sans text-db text-text2 tracking-[-0.3px] leading-[150%] font-[400] ">
                Largely GE has two types of site - Onshore (on the land) and
                Offshore (in the ocean).{" "}
                <span className="font-[700]">
                  Mostly we worked for onshore sites but this time request was
                  from offshore team.
                </span>
              </p>
            </div>
            <div>
              <Lottie animationData={HeroAnimation} loop={true} />
            </div>
          </div>
          <div className="w-full flex justify-between gap-[16px] items-start">
            <div className="flex flex-col w-full gap-[24px] ">
              <div className="flex flex-col gap-[8px] w-full">
                <div className="text-dh2 font-script text-text1 font-[400] ">
                  My Role
                </div>
                <p className="font-sans text-db text-text2 tracking-[-0.32px] leading-[150%] font-[400] ">
                  Designing end to end experience.
                </p>
              </div>

              <div className="flex flex-col gap-[8px] w-full">
                <div className="text-dh2 font-script text-text1 font-[400] ">
                  Team
                </div>

                <p className="font-sans text-db text-text2 tracking-[-0.32px] leading-[200%] font-[400] ">
                  Punit Gupta (Me) - Product Designer <br /> Ishani - Design
                  Lead <br />
                  Boniface - Design Manager <br /> Ivan - Product Manager & Data
                  Scientist
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[8px] w-full">
              <div className="text-dh2 font-script text-text1 font-[400] ">
                Tasks Performed
              </div>

              <p className="font-sans text-db text-text2 tracking-[-0.32px] leading-[200%] font-[400] ">
                POC design for funding
                <br /> Design Strategy <br />
                Requirement gathering from users & business <br />
                Workflow Mapping <br />
                Benchmark Study
                <br />
                Layout & Wireframe Designs <br />
                Visual & Interaction Design <br />
                Prototyping & Handoff
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[8px]">
            <div className="font-script text-dh2 text-text1 font-[400]">
              Story Time - Context
            </div>
            <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[160%] text-text1 font-[400]">
              <p>
                <span className="font-[700]">
                  Maintenance of offshore sites is
                </span>
                one of the{" "}
                <span className="font-[700]">toughest yet adventures </span>{" "}
                part of the <span className="font-[700]">job</span> for
                engineers and site managers. Even though these{" "}
                <span className="font-[700]">
                  sites are located in the middle of ocean,
                </span>{" "}
                they need regular maintenance. To{" "}
                <span className="font-[700]">perform those maintenance </span>{" "}
                site managers and <span className="font-[700]">engineers</span>{" "}
                have to{" "}
                <span className="font-[700]"> travel to the turbine </span> with
                the{" "}
                <span className="font-[700]">
                  {" "}
                  help of vessel (large boats).{" "}
                </span>
                Fascinating? 😳. But But But all of these maintenance take place
                with a lot of planning and process and we are going to solve for
                that in this project.
              </p>
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.ProblemStatement}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              PROBLEM STATEMENT
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Ch. 1 - Survival : How Might we
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  help offshore users intuitively visualize and interact with
                  dynamic data to improve planning and decision-making?
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[16px]">
              <HighlightContainer
                title={"Why Survival?"}
                body={
                  "Initially, we created a proposal concept to secure funding for this project. Once the project managers obtained approval, we proceeded with the full implementation."
                }
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Discovery{" "}
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Requirements from Business
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  Our first call was with the data science team, they came up
                  with the requirement. In the call we talked about the problems
                  and possibilities. We asked questions to get more clarity of
                  the requirement.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[16px]">
              <HighlightList
                title={"Problem statements"}
                list={[
                  "Offshore wind users require the ability to add layers of information onto a baseline map in order to plan and execute visits.",
                  "Map should display information like turbine location, support vessel (large boat), O&M team locations (workers), list of turbines with preventative maintenance, weather conditions etc.",
                ]}
              />
              <HighlightList
                title={"Execution Strategy"}
                list={[
                  "A preliminary concept need to be put together in next 2 weeks for funding.",
                  "Ivan(PM) has gathered some requirement from some users for initial concept.",
                  "Extensive designs will be done once we will secure funding.",
                  "Design team can gather more information from user after the green signal from the execution team.",
                  "For concept, design team can refer to MTM where we use map to show live assets.",
                ]}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              defining Hypothesis
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Next step was to build hypothesis for the early concept.
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  We had below three sources of information which help us to
                  build initial hypothesis.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">
                  1. Our prior understanding of MTM (mobile task management)
                  model.{" "}
                </p>
                <p className="font-[700]">
                  2. Other similar solution on the internet.
                </p>
                <p className="font-[700]">
                  3. Detailed requirement from business
                </p>
              </div>

              <ImageWrapper
                img={Hypothesis}
                caption={
                  "Hypothesis derived from Goole/Apple Maps, Zoom Earth and MTM"
                }
              />
            </div>
          </div>
          {/* <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Ideation
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Wireframes
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  Based on the hypothesis I put together some rough wireframes
                  to make sure the layout is clear in my mind and I don’t miss
                  anything. While sketching below points i kept in mind
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">
                  1. Weather layer for planning (forecasted){" "}
                </p>
                <p className="font-[700]">
                  2. Asset layer control based on current asset condition.
                </p>
                <p className="font-[700]">
                  3. Only one vessels visible and moving on the map
                </p>
              </div>

              <ImageWrapper
                img={Hypothesis}
                caption={
                  "Hypothesis derived from Goole/Apple Maps, Zoom Earth and MTM"
                }
              />
            </div>
          </div> */}
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.POCDesign}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              first concept
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Visual Design for POC
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  With heavy influence from the references and understanding
                  from MTM. We did a UI and prototype which can be shared with
                  the executive team as proof of concept.
                </p>
                <p className="font-[700]">
                  Since we have the lifespan design system it was very easy for
                  us to do the visuals for POC.
                </p>
              </div>
              <div className="w-full pt-[16px] pb-[24px] flex flex-col justify-start items-start gap-[8px]">
                <div className="p-[24px] flex flex-col gap-[24px] bg-background5 rounded rounded-[8px] border-[1px] border-stroke">
                  <img src={POC1} className="w-full h-auto aspect-auto" />
                  <img src={POC2} className="w-full h-auto aspect-auto" />
                  <img src={POC3} className="w-full h-auto aspect-auto" />
                  <img src={POC4} className="w-full h-auto aspect-auto" />
                </div>

                <div className="font-mono text-dc font-[400] text-text2">
                  First concept for funding form executives
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              THE winning shot
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                After one week…
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  We finally heard back from Ivan, that we have gotten the
                  funding to fully execute the project 🎉.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div
              className="font-mono text-[12px] text-dst text-text3 uppercase "
              ref={scrollRefs.DetailedRequirementGathering}
            >
              discovery cont..
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Chapter 2 - Rebuilding
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  After the green light, we decided we will gather more precise
                  requirement from the potential users. Our next steps were
                </p>
              </div>
            </div>
            <HighlightList
              title={"Updated execution strategy"}
              list={[
                "The design team will start with a discovery phase to deeply understand user needs and gather detailed requirements.",
                "We’ll conduct a benchmark analysis to ensure our maps align with industry standards and match how users naturally think and navigate.",
                "The maps will be developed in phases, with the MVP set to launch next quarter for user testing.",
                "While the primary focus is on offshore sites, the design should also consider onshore use cases to ensure a smooth adoption with minimal effort.",
                "Creating a high-fidelity, interactive prototype will allow us to gather valuable user feedback before moving into development.",
              ]}
            />
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              understanding problem
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Demo and user interview
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  I asked Ivan to setup a call with potential users to collect
                  better insights, In the call we asked questions and also
                  talked about their workflow for maintenance.
                </p>
                <p>
                  Since we already had a concept from the funding presentation,
                  we showed that concept as well which helped us to ask better
                  questions.
                </p>
              </div>
            </div>
            <ImageWrapper caption={"User Demo for validation"} img={UserDemo} />
            <HighlightList
              title={"Outcomes"}
              list={[
                "There's a strong need to move beyond spreadsheet-based operational planning.",
                "Users highly value visualizing complex data for better planning and safety.",
                "Provide a clear visual indication when workers are outside the safety/response zone.",
                "Implement customisable visual indicators for various tasks on turbines.",
                "Users need to see status of specific tasks (not just turbine status) visually.",
                "Safety circle was misleading regarding response zones and different vessel types. Large vessel will have smaller safety zone.",
                "Implementing some features is dependent on data availability",
              ]}
            />
          </div>
          <div
            className="w-full flex flex-col gap-[60px]"
            //   ref={scrollRefs.Ideation}
          >
            <div
              className="w-full flex flex-col gap-[24px]"
              ref={scrollRefs.WorkflowMapping}
            >
              <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
                Workflow Mapping
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-script text-dh2 text-text1 font-[400]">
                  Our interviews with users led us to map for two major goals.
                </div>
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <p>
                    To better understand maps integrate into the process, we
                    mapped out the workflow for both use cases. The outcome of
                    mapping helped us to connect the dot of map with the larger
                    asset maintenance and task execution process.
                  </p>
                  <p className="font-[700]">1. Site Visit Planning</p>
                  <p className="font-[700]">2. Site Visit Execution</p>
                </div>
              </div>
              <HighlightList
                title={"Goal"}
                list={[
                  "Understand where map fits in the large workflow ",
                  "What makes users to use map. (Entry Points)  ",
                  "What are the next steps after using map. (Exit Points) ",
                ]}
              />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Planning Workflow</p>
              </div>
              <ImageWrapper
                caption={"Mapping of planning workflow"}
                img={PlanningWorkflow}
              />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Execution Workflow</p>
              </div>
              <ImageWrapper
                caption={"Mapping of execution workflow"}
                img={ExecutionWorkflow}
              />
            </div>
          </div>

          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.BenchmarkingStudy}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Benchmark Study
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                For better insights I also looked at other map solutions
                available out there like zoom earth etc.
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  For better insights I also looked at other map solutions
                  available out there like zoom earth etc.
                </p>
              </div>
            </div>
            <HighlightList
              title={"Goal"}
              list={[
                "To understand how to reflect real time data on the map. ",
                "Identify the best practices for map design. ",
                "What are the most common controls available on the map.",
                "Layout of the maps (where goes what).",
                "Potential interaction on the map.",
              ]}
            />
            <ImageWrapper caption={"Benchmark study "} img={BenchmarkStudy} />
            <div className="w-full flex flex-col gap-[24px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Outcomes</p>
              </div>
              <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  Zoom Earth
                </div>
                <div className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                  <span className="font-[700]">
                    Best inspiration to surface weather on map.
                  </span>{" "}
                  Zoom has an very interesting blend way to visualise different
                  weather data like precipitation, wind direction, lightning
                  etc. It became our go to reference for weather visual
                  representation.
                </div>
              </div>
              <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  Flight Radar
                </div>
                <div className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                  <span className="font-[700]">
                    Very interesting approach to displaying planes, which can
                    help us visualize how to position vessels on the map.
                  </span>{" "}
                  Flight details card on FR is also extensive and was
                  influential for our vessel card.
                </div>
              </div>
              <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  GB Renewable
                </div>
                <div className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                  <span className="font-[700]">
                    Helpful in terms of showing asset information on the map.
                  </span>{" "}
                  The visualizations provided useful examples, but the
                  underlying data wasn't relevant to our needs.
                </div>
              </div>
              <div className="w-full  p-[16px] flex flex-col gap-[12px] justify-start items-start bg-background5 rounded rounded-[8px] border-[1px] border-stroke ">
                <div className="w-full font-sans tracking-[-0.32px] text-db font-[700] text-text2">
                  Google/Apple Map
                </div>
                <div className="w-full font-sans tracking-[-0.32px] leading-[150%] text-db font-[400] text-text2">
                  <span className="font-[700]">
                    Being industry-standard maps, they helped us align our
                    design with users' existing mental models.
                  </span>{" "}
                  It was easier to make decisions about the placement of zoom
                  controls and scale after studying these maps
                </div>
              </div>
              <HighlightList
                title={"Common Observation across all of them"}
                list={[
                  "Layer controls are consistently positioned in the top-left corner of the interface.",
                  "All weather layers are toggle (boolean controls) but multiple weather conditions can be seen at a time.",
                  "Standard zoom in/out through scroll and button on the right bottom corner (good for accessibility).",
                  "Double clicking on specific object will zoom into the detailed view.",
                  "The map itself should cover 80% of the screen view.",
                  "A quick search to find out any object on the map.",
                ]}
              />
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.EpicLevelStories}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              epic level user stories
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                After collecting all the information, I converge them and put
                those into stories.
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  We put all the findings in the form of epic level user
                  stories. This way we captured all the requirement and
                  synthesise based on our needs.
                </p>
              </div>
            </div>
            <ImageWrapper
              caption={"User Demo for validation"}
              img={userStories}
            />
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.Ideation}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Ideation
            </div>
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Feature Mapping
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  Basis on the gathered data and our understanding of baseline
                  map (google map) we did exercise of feature mapping where we
                  decided interaction for all the given information.{" "}
                  <span className="font-[700]">
                    I divided all the feature set into 5 categories.
                  </span>
                </p>
              </div>
            </div>

            <HighlightContainer
              title={"Hide/Show"}
              body={
                "Layers that are boolean and can be turn on and off basis on the need."
              }
            />
            <HighlightContainer
              title={"Styling/Representation"}
              body={
                "Different style of map or unique representation of critical information."
              }
            />
            <HighlightContainer
              title={"Filters"}
              body={
                "Quick filters to simplify view of the map, e.g Only show tripped assets."
              }
            />

            <HighlightContainer
              title={"Drill Down "}
              body={
                " A way to see second layer of information. e.g. More details about vessel or worker. "
              }
            />
            <HighlightContainer
              title={"Others"}
              body={"Anything else which is essential to show on map."}
            />
            <div className="w-full pt-[16px] min-h-screen pb-[24px] flex flex-col justify-start items-start gap-[8px]">
              <div className="p-[24px] h-auto w-full bg-background5 rounded rounded-[8px] border-[1px] border-stroke">
                <iframe
                  allowFullScreen
                  style={{ border: "1px solid " }}
                  className="w-full min-h-screen"
                  src="https://embed.figma.com/board/hgrX4ydBMoFJeXRmyevrzC/GE-Lifespan-Map-View?node-id=194-2787&embed-host=share"
                ></iframe>
              </div>
              <div className="font-mono text-dc font-[400] text-text2">
                {" "}
                Feature Mapping for realtime map{" "}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="w-full flex  flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                Layout & Wireframes
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  Once I decided the skeleton of the map. I moved into lo-fi
                  wireframes. Before getting into flashed visuals, team wanted
                  to get validation on wireframes, to set the right direction
                  for visuals.
                </p>
              </div>
            </div>
            <div className="w-full pt-[16px] pb-[24px] flex flex-col justify-start items-start gap-[8px]">
              <div className="p-[24px] flex flex-col gap-[24px] bg-background5 rounded rounded-[8px] border-[1px] border-stroke">
                <img src={Wireframe1} className="w-full h-auto aspect-auto" />
                <img src={Wireframe2} className="w-full h-auto aspect-auto" />
                <img src={Wireframe3} className="w-full h-auto aspect-auto" />
                <img src={Wireframe4} className="w-full h-auto aspect-auto" />
              </div>

              <div className="font-mono text-dc font-[400] text-text2">
                Wireframes
              </div>
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[60px]"
            //   ref={scrollRefs.Ideation}
          >
            <div
              className="w-full flex flex-col gap-[24px]"
              ref={scrollRefs.InteractionAndVisualDesigns}
            >
              <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
                interactions
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-script text-dh2 text-text1 font-[400]">
                  Designing pieces of the puzzle
                </div>
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <p>
                    Before doing the full flash visuals, we designed the
                    components and interaction.
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <p className="font-[700]">Left Controls</p>
                </div>
                <ImageWrapper
                  caption={"Iterations of left control bar`"}
                  img={LeftControls}
                />
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <p className="font-[700]">
                    Right Panels - Preventative task and Worker Present
                  </p>
                </div>
                <ImageWrapper
                  caption={"Iterations of Right panel"}
                  img={RightPanel}
                />
              </div>
              <div className="w-full flex flex-col gap-[8px]">
                <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                  <p className="font-[700]">Hover Card Iterations</p>
                </div>
                <ImageWrapper caption={"Vessel hover card"} img={VesselCard} />
              </div>
              <ImageWrapper caption={"Asset hover card"} img={AssetCard} />
            </div>
          </div>
          <div className="w-full flex flex-col gap-[24px]">
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              final visual designs
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-script text-dh2 text-text1 font-[400]">
                We collated all the wireframes and potential improvements to the
                stakeholders for final validation. With small tweaks we all
                settled on final design.
              </div>
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p>
                  For visuals I leveraged lifespan design system which we have
                  been maintaining since the beginning of the engagement with
                  GE.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Default Map - Site view</p>
              </div>
              <ImageWrapper
                caption={"Default Map - Site view"}
                img={MapVisual1}
              />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Heat-map for weather layers </p>
              </div>
              <ImageWrapper
                caption={"Heat-map for weather layers "}
                img={MapVisual2}
              />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Tasks Panel </p>
              </div>
              <ImageWrapper caption={"Tasks Panel "} img={MapVisual3} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Worker Present Panel </p>
              </div>
              <ImageWrapper
                caption={"Worker Present Panel "}
                img={MapVisual4}
              />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Live Vessel</p>
              </div>
              <ImageWrapper caption={"Live Vessel "} img={MapVisual5} />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <p className="font-[700]">Tasks Filters </p>
              </div>
              <ImageWrapper caption={"Tasks Filters "} img={MapVisual6} />
            </div>
          </div>
          <div
            className="w-full flex flex-col gap-[24px]"
            ref={scrollRefs.Learnings}
          >
            <div className="font-mono text-[12px] text-dst text-text3 uppercase ">
              Outcome
            </div>
            <div className="font-script text-dh2 text-text1 font-[400]">
              Impact & Metric
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <div className="font-sans space-y-2 text-db tracking-[-0.32px] leading-[150%] text-text1 font-[400]">
                <ul className="font-[400] list-[square] list-outside space-y-[8px] pl-4">
                  <li>
                    Reduced the site visit planning time to 60%, since
                    everything became digital.
                  </li>
                  <li>reduced execution time by ~40%.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PasswordProtectedPage>
  );
};
