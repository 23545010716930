import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC6-gtSBBQv9mv6iQO0sC8dgkP2m5mJz_Y",
  authDomain: "test-ab1b1.firebaseapp.com",
  databaseURL: "https://test-ab1b1.firebaseio.com",
  projectId: "test-ab1b1",
  storageBucket: "test-ab1b1.appspot.com",
  messagingSenderId: "940037673808",
  appId: "1:940037673808:web:0f341874599ccbfb801255",
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage, ref, listAll, getDownloadURL };
