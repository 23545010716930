import React, { useEffect } from "react";
import { Star2 } from "../../../Icons/Stars";
import { useAnimationControls, motion } from "framer-motion";

export const MarqueeImage = ({ images }) => {
  // Duplicate the array **only once** for smooth looping
  const repeatArray = [...images, ...images];

  const controls = useAnimationControls();
  useEffect(() => {
    controls.start({
      x: "-50%", // Shift only half so it seamlessly loops
      transition: {
        duration: 70, // Adjust for smooth speed
        ease: "linear",
        repeat: Infinity,
      },
    });
  }, [controls]);

  return (
    <div className="relative w-full overflow-hidden py-10">
      <motion.div
        className="inline-flex whitespace-nowrap gap-[24px] items-center py-[16px]  text-foreground1 font-sans uppercase font-[700] text-body"
        animate={controls}
      >
        {repeatArray.map((src, index) => (
          <span className=" h-[max-content] w-[max-content] justify-center items-center relative inline-flex  p-[16px] border-[1px] border-[#4199F8] ">
            <img
              src={src}
              key={index}
              className="min-w-max object-cover w-[268px] h-[364px]"
            />
            <div className="absolute inset-0 flex justify-between items-center pointer-events-none">
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] left-[-4px]"></div>
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] right-[-4px]"></div>
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] left-[-4px]"></div>
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] right-[-4px]"></div>
            </div>
          </span>
        ))}
      </motion.div>
    </div>
  );
};
