import { motion } from "framer-motion";

export const DrawnLine = () => {
  return (
    <svg
      width="77"
      height="59"
      viewBox="0 0 77 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.7411 1.97218C53.0694 0.22802 50.1224 0.525694 48.2808 0.955058C46.2894 1.41933 44.2625 2.3391 42.3559 3.39609C38.5006 5.53344 34.029 8.86142 29.4314 12.7749C20.1845 20.6459 9.63522 31.5685 1.22998 42.1505C-0.727879 44.6154 -0.305507 48.1861 2.19145 50.1357C4.7031 52.0967 8.34379 51.6617 10.313 49.1825C18.283 39.1484 28.3197 28.7677 36.9687 21.4057C39.6125 19.1552 42.0543 17.2491 44.2107 15.741C42.1036 19.1868 39.3337 23.1793 36.4063 27.3207C35.8838 28.0599 35.3571 28.8026 34.8305 29.5453L34.7907 29.6014C31.6237 34.0678 28.4198 38.5863 26.2077 42.2321C24.9568 44.2936 23.7386 46.5262 23.1286 48.529C22.8418 49.4709 22.4619 51.0586 22.802 52.8098C22.9897 53.7763 23.4187 54.8971 24.2893 55.9153C25.1746 56.9507 26.2748 57.5965 27.353 57.9485C30.1513 58.8618 32.9253 58.1144 34.6353 57.5082C36.5857 56.8168 38.633 55.7516 40.6037 54.5881C44.5615 52.2512 49.0712 48.9949 53.2693 45.9049C54.0146 45.3563 54.7525 44.8112 55.479 44.2745L55.4864 44.269C57.7449 42.6005 59.881 41.0228 61.8345 39.6382C61.1188 40.5942 60.499 41.3188 60.1903 41.6211C57.9338 43.8307 57.9116 47.4252 60.1397 49.6619C62.3811 51.912 66.0487 51.9349 68.3187 49.7121C69.9446 48.1201 73.1038 44.0991 75.074 40.0293C76.0255 38.064 77.0812 35.3329 76.995 32.5225C76.9487 31.0101 76.5624 29.2223 75.4009 27.573C74.2091 25.8806 72.5324 24.8055 70.7449 24.222C69.0069 23.6547 67.4124 23.8796 66.4706 24.0903C65.4434 24.3201 64.4765 24.7004 63.6517 25.0795C61.9989 25.8391 60.1885 26.9369 58.4069 28.1118C55.4475 30.0635 51.9801 32.6256 48.5504 35.1598L48.5098 35.1898C47.7949 35.718 47.0809 36.2456 46.3702 36.7687C44.5742 38.0906 42.8112 39.3751 41.1186 40.5685C42.1164 39.1426 43.1748 37.6493 44.2645 36.1121L44.2806 36.0894C44.8056 35.3488 45.3374 34.5985 45.8719 33.8423C49.5909 28.5811 53.5271 22.9127 56.0708 18.2136C57.3144 15.9162 58.4682 13.4196 58.9603 11.0864C59.375 9.12056 59.7978 4.62036 55.7411 1.97218Z"
        fill="#4FC507"
      />
    </svg>
  );
};
