import React from "react";
import { RotatingStar, Star1, Star3 } from "../../../Icons/Stars";
import PerformanceImage from "../../../assets/DjPerformance.webp";
import { MarqueeText } from "../NewComponents/MarqueeText";
import { Waveform } from "../../../Icons/HeroIcons";
import { Star2 } from "../../../Icons/Stars";
import Image1 from "../../../assets/AboutMe/Image1.webp";
import Image2 from "../../../assets/AboutMe/Image2.webp";
import Image3 from "../../../assets/AboutMe/Image3.webp";
import Image4 from "../../../assets/AboutMe/Image4.webp";
import Image5 from "../../../assets/AboutMe/Image5.webp";
import Image6 from "../../../assets/AboutMe/Image6.webp";
import Image7 from "../../../assets/AboutMe/Image7.webp";
import Image8 from "../../../assets/AboutMe/Image8.webp";
import Image9 from "../../../assets/AboutMe/Image9.webp";
import Image10 from "../../../assets/AboutMe/Image10.webp";
import Image11 from "../../../assets/AboutMe/Image11.webp";
import Image12 from "../../../assets/AboutMe/Image12.webp";
import Image13 from "../../../assets/AboutMe/Image13.webp";
import Image14 from "../../../assets/AboutMe/Image14.webp";

import { MarqueeImage } from "../NewComponents/MarqueeImage";
import { MovingText } from "../NewComponents/MovingText";
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useAnimationFrame,
  distance,
} from "framer-motion";
import HeroImage from "../../../assets/aboutmeHeroimage.webp";
import { Pizza } from "../../../Icons/Pizza";
import FloatingTooltip from "../NewComponents/HoverWrapper";
import { useRef, useState } from "react";
import SmileyFace from "../NewComponents/SmilyFace";
import { DrawnLine } from "../NewComponents/DrawnLine";
import { FloatingWrapper } from "../NewComponents/FloatingWrapper";
import POSM from "../../../assets/Book Covers/Power of subconscious mind.webp";
import PI from "../../../assets/Book Covers/predictabilty irrational.webp";
import TFAS from "../../../assets/Book Covers/Thinking Fast and slow.webp";
import UPOD from "../../../assets/Book Covers/universal principles of design.webp";

const AboutMe1 = () => {
  const [message, setMessage] = useState("You are awesome");
  const [textColor, setTextColor] = useState("text-[#1CAF05]");
  const [distance, setDistance] = useState(0);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const Images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14,
  ];

  useAnimationFrame(() => {
    const distance = Math.hypot(x.get(), y.get());
    setDistance(distance);

    // Change text based on distance
    if (distance > 170) {
      setMessage("You are awful");
      setTextColor("text-red-500");
    } else {
      setMessage("You are awesome");
      setTextColor("text-[#1CAF05]");
    }
  });
  const Texts = [
    "Chole Bhature",
    <Star2 />,
    "music production",
    <Star2 />,
    "djing",
    <Star2 />,
    "techno",
    <Star2 />,
    "skrillex",
    <Star2 />,
    "dubstep",
    <Star2 />,
    "trekking",
    <Star2 />,
    "martin garrix",
    <Star2 />,
  ];

  return (
    <div className=" flex flex-col justify-center items-center gap-[10px]">
      <div className="grid grid-cols-12 mt-[142px] gap-x-[16px] gap-y-[16px]  h-[90vh]  mx-[48px] max-w-[1470px] place-content-start ">
        <div className="col-span-8 col-start-3 flex h-[min-content]   flex-wrap items-start  w-full gap-[20px] ">
          <div className="flex flex-wrap gap-y-[8px]  justify-center w-full gap-[16px]">
            <motion.div
              initial={{ scaleX: 0.8, opacity: 0 }}
              animate={{ scaleX: 1, opacity: 1 }}
              exit={{ scaleX: 0.8, opacity: 0 }}
              transition={{
                type: "spring",
                stiffness: 250, // Higher value makes it bouncy
                damping: 20, // Reduces the bounce
              }}
              className="text-[38px] max-w-full text-center  font-serif text-foreground1 font-bold"
            >
              If synths, slices, and endless curiosity had a spokesperson,
              that’d be me.
            </motion.div>

            {/* <div className="text-[38px] font-serif text-foreground1 font-bold">
              <span
                className={`h-[64px] text-[38px] font-serif text-foreground1 font-bold  justify-center items-center relative inline-flex bg-[#CDEEFC] px-[2px] border-[1px] border-[#4199F8]`}
              >
                Always Tinkering
                <div className="absolute inset-0 flex justify-between items-center pointer-events-none">
                  <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] left-[-4px]"></div>
                  <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] right-[-4px]"></div>
                  <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] left-[-4px]"></div>
                  <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] right-[-4px]"></div>
                </div>
              </span>
              <br />
            </div> */}

            {/* <div className="text-[38px] flex items-center justify-center   font-serif text-foreground1 font-bold">
              <AnimatePresence mode="wait">
                <motion.span
                  className={`h-[64px] text-[38px] w-auto font-serif text-foreground1 font-bold  justify-center items-center relative inline-flex bg-[#FCF2CD] px-[2px] ml-[10px] border-[1px] border-[#4199F8]`}
                  initial={{ scaleX: 0.8, opacity: 0 }}
                  animate={{ scaleX: 1, opacity: 1 }}
                  exit={{ scaleX: 0.8, opacity: 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 250, // Higher value makes it bouncy
                    damping: 20, // Reduces the bounce
                  }}
                >
                  Often Breaking Rules
                  <div className="absolute inset-0 flex justify-between items-center pointer-events-none">
                    <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] left-[-4px]"></div>
                    <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] right-[-4px]"></div>
                    <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] left-[-4px]"></div>
                    <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] right-[-4px]"></div>
                  </div>
                </motion.span>
              </AnimatePresence>
            </div> */}
          </div>
        </div>

        <div className="font-sans col-span-6 mt-[25px] col-start-4  text-center tracking-[-0.64px] leading-[160%]  text-[16px] w-auto text-foreground1 ">
          I can <span className="font-[700]">center my own divs</span> , xd!.
          Have been <span className="font-[700]"> moving pixels </span> since
          9th grade. Fascinated with{" "}
          <span className="font-[700]">sound, motion </span> and{" "}
          <span className="font-[700]">3 pounds of human brain </span>
        </div>
        <motion.div className="col-span-2 col-start-6">
          <img className="w-auto h-auto" src={HeroImage} />
        </motion.div>
        <motion.div
          className="relative flex flex-col items-center justify-center  cursor-pointer  left-[-400px] top-[30px]"
          drag
          dragConstraints={{ left: -200, right: 1000, top: -200, bottom: 50 }} // Limit movement
          dragElastic={0.2}
          style={{ x, y }}
        >
          <FloatingTooltip tooltipText={"Do not take pizza away from me :/"}>
            <FloatingWrapper>
              <Pizza />
            </FloatingWrapper>
          </FloatingTooltip>

          <div
            className={`font-sans w-[max-content] uppercase font-[800] ${textColor} text-[16px] `}
            style={{ WebkitTextStroke: "0.5px black" }}
          >
            {message}
          </div>
        </motion.div>

        <div className="relative left-[250px] bottom-[50px]">
          <FloatingWrapper>
            <SmileyFace distance={distance} />
          </FloatingWrapper>
        </div>
        <div className="relative left-[250px] bottom-[300px]">
          <RotatingStar />
        </div>
        <div className="absolute left-[150px] top-[600px]">
          <RotatingStar />
        </div>
        <div className="relative left-[-1000px] bottom-[250px] ">
          <FloatingWrapper>
            <DrawnLine />
          </FloatingWrapper>
        </div>
      </div>

      <MarqueeText texts={Texts} bgColor={"#979FFC"} />

      <div className=" grid grid-cols-12 gap-[16px]  mx-[48px] max-w-[1470px]  mt-[40px] h-[auto]">
        <div className=" col-span-6 col-start-4 items-start flex flex-col gap-[120px]">
          <div className="flex flex-col items-start gap-[12px] justify-center">
            <div className="flex gap-[4px] text-[20px] font-[900] items-baseline justify-center font-serif text-foreground1">
              I’m obsessed with
              <span className="text-[32px] pl-[4px] font-[400] italic font-script text-foreground1">
                Wave forms
              </span>
            </div>
            <div className="font-sans tracking-[-0.64px] text-[16px] w-[full] text-foreground1 leading-[160%] ">
              Sound design is my playground alongside product design. I see it
              as a powerful way to{" "}
              <span className="font-[700]">nudge human perception through</span>
              the art of <span className="font-[700]">sound.</span> Using Serum
              in FL Studio, I am learning to craft immersive audio experiences,
              drawn to the raw energy of saw waves and the purity of sine waves.
            </div>
            <div className="w-[100%] h-[100%] mt-[32px]">
              <Waveform />
            </div>
          </div>
          <div className="flex flex-col items-start gap-[12px] justify-center">
            <div className="flex gap-[4px] text-[20px] font-[900] items-baseline justify-center font-serif text-foreground1">
              Nodding my head on
              <span className="text-[32px] pl-[4px] font-[400] italic font-script text-foreground1">
                Techno
              </span>
            </div>
            <div className="font-sans tracking-[-0.64px] text-[16px] w-[full] text-foreground1 leading-[160%] ">
              If you ever find me nodding my head with my ear plug on, I must be
              listening to techno. Big fan boy of
              <span className="font-[700]"> Fred again and Skrillex </span>
              <span className="text-[#837C7C]">
                {" "}
                (I know he is more of dubstep guy, don’t judge )
              </span>
            </div>
            <div className="w-[100%] h-[100%] flex flex-col gap-[16px] mt-[32px]">
              <iframe
                style={{ borderRadius: "12px" }}
                src="https://open.spotify.com/embed/track/4AA57loUSZKtuHdULvizrc?utm_source=generator&theme=0"
                width="100%"
                height="152"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
              <iframe
                style={{ borderRadius: "12px" }}
                src="https://open.spotify.com/embed/track/74fmYjFwt9CqEFAh8ybeBD?utm_source=generator"
                width="100%"
                height="152"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="flex flex-col items-start gap-[12px] justify-center">
            <div className="flex gap-[4px] text-[20px] font-[900] items-baseline justify-center font-serif text-foreground1">
              Reading about
              <span className="text-[32px] pl-[4px] font-[400] italic font-script text-foreground1">
                Human behaviour
              </span>
            </div>
            <div className="font-sans tracking-[-0.64px] text-[16px] w-[full] text-foreground1 leading-[160%] ">
              Always wondering{" "}
              <span className="font-[700]">“ why human do what they do”.</span>{" "}
              Often times you will find me observing humans around me. I love
              reading books related to human behaviour or neuroscience.
            </div>
            <div className=" grid grid-cols-3 gap-[24px] justify-items-start  mt-[32px]">
              <FloatingTooltip
                tooltipText={
                  "  The subconscious mind absorbs repeated visual and mental images, influencing real-world outcomes."
                }
              >
                <img
                  src={POSM}
                  className="object-cover w-[180px] h-auto aspect-ratio hover:shadow-[8px_8px_0px_rgba(0,0,10,.1)] transition-all duration-300 ease-in-out  hover:-translate-y-2"
                />
              </FloatingTooltip>
              <FloatingTooltip
                tooltipText={
                  "Fitts’s Law – The time to reach a target (like a button) depends on its size and distance."
                }
              >
                <img
                  src={UPOD}
                  className="object-cover w-[180px] h-auto aspect-ratio hover:shadow-[8px_8px_0px_rgba(0,0,10,.1)] transition-all duration-300 ease-in-out  hover:-translate-y-2"
                />
              </FloatingTooltip>
              <FloatingTooltip
                tooltipText={
                  "People don’t evaluate things in absolute terms; they compare them to what’s around them."
                }
              >
                <img
                  src={PI}
                  className="object-cover w-[180px] h-auto aspect-ratio hover:shadow-[8px_8px_0px_rgba(0,0,10,.1)] transition-all duration-300 ease-in-out  hover:-translate-y-2"
                />
              </FloatingTooltip>
              <FloatingTooltip
                tooltipText={
                  "Users rely on instinctive, fast decision-making (System 1) unless forced to slow down."
                }
              >
                <img
                  src={TFAS}
                  className="object-cover w-[180px] h-auto aspect-ratio hover:shadow-[8px_8px_0px_rgba(0,0,10,.1)] transition-all duration-300 ease-in-out  hover:-translate-y-2"
                />
              </FloatingTooltip>
            </div>
          </div>
        </div>
      </div>

      <MarqueeImage images={Images} />
    </div>
  );
};

export default AboutMe1;
