import { HomeIcon } from "../Icons/HomeIcon";

import { TopIcon } from "../Icons/TopIcon";
import { cn } from "../utils/cn";
import { Button, Switch } from "@nextui-org/react";
import { VisuallyHidden, useSwitch } from "@nextui-org/react";
import { motion, useSpring, useMotionValue } from "framer-motion";
import { useTheme } from "next-themes";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { ScrollIcon } from "./layouts/NewComponents/ProjectCardIcons";

function ButtonWithGradientAndAnimation({ label, action }) {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const button = document.getElementById("shimmerButton");
    if (button) {
      if (isHovered) {
        button.classList.add("animate-shimmer");
      } else {
        button.classList.remove("animate-shimmer");
      }
    }
  }, [isHovered]);

  return (
    <button
      id="shimmerButton"
      className="inline-flex h-10 w-max items-center justify-center rounded-md border border-gray-900 px-6 font-bold text-white text-sm transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
      style={{
        backgroundImage:
          "linear-gradient(110deg, #000103 45%, #1e2631 55%, #000103)",
        backgroundSize: "200% 100%",
        transition: "background-position 2s linear",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={action}
    >
      {label}
    </button>
  );
}

export default ButtonWithGradientAndAnimation;

export const HomeButton = () => {
  const navigation = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",

      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  return (
    <div
      className="dark flex flex-col gap-4 "
      style={{ position: "fixed", zIndex: 1200, left: "90%", bottom: "40px" }}
    >
      <Button
        color="default"
        variant="solid"
        startContent={<HomeIcon />}
        size="lg"
        onClick={() => navigation("/")}
        isIconOnly
      ></Button>
      <Button
        color="default"
        variant="solid"
        startContent={<TopIcon />}
        size="lg"
        onClick={scrollToTop}
        isIconOnly
      ></Button>
      <ThemeSwitcher />
    </div>
  );
};

export const ButtonV2 = ({ label, action }) => {
  const tickSound = new Audio("/Click2.mp3");
  tickSound.volume = 0.3;
  const handleClick = () => {
    tickSound.play();
    action();
  };
  return (
    <>
      <button
        className="relative group/btn flex items-center justify-center w-auto rounded-md h-10 font-normal hover:text-white ease-out duration-500"
        onClick={handleClick}
      >
        <span
          className=" uppercase text-foreground2 font-medium text-sm text-center w-max hover:text-foreground1 ease-out duration-500"
          style={{ fontFamily: "" }}
        >
          {label}
        </span>
      </button>
    </>
  );
};
export const ButtonV3 = ({ label, action }) => {
  const tickSound = new Audio("/Click2.mp3");
  tickSound.volume = 0.3;
  const handleClick = () => {
    tickSound.play();
    action();
  };
  return (
    <>
      <button
        className="  flex items-center text-[#ffffff80] font-normal  text-[14px] justify-center w-[auto] px-[12px] rounded-[8px]   h-10  hover:bg-backgroun  text-[13px] text-center  hover:text-foreground1 ease-out duration-500"
        onClick={handleClick}
        style={{ fontFamily: "MonaSans" }}
      >
        {label}
      </button>
    </>
  );
};

export const IconButton = ({ label, icon, action }) => {
  const tickSound = new Audio("/Click.mp3");
  tickSound.volume = 0.3;
  const handleClick = () => {
    tickSound.play();
    action();
  };
  return (
    <>
      <button
        className="relative flex text-[16px] gap-[10px] lowercase items-center justify-center h-[auto] w-[auto] px-[12px] py-[12px] rounded-[8px] border-[1px] border-background2 hover:bg-background2 ease-out 
        duration-500 "
        onClick={handleClick}
      >
        {label}
        {icon}
      </button>
    </>
  );
};
export const Status = ({ input }) => {
  return (
    <>
      <div className="flex h-8 w-max  bg-background text-foreground rounded-[0.25rem] px-0 py-2  text-[14px] font-bold items-center gap-x-2 border-0 border-background2 sm:text-[14px] ">
        <div className="rounded-[0.25rem] bg-lime-500 h-2 w-2"></div>
        <p className="t-white uppercase"> {input}</p>
      </div>
    </>
  );
};

export function ThemeSwitcher() {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "default"
  );

  useEffect(() => {
    if (theme === "neubrutalism") {
      document.documentElement.classList.add("neubrutalism");
    } else {
      document.documentElement.classList.remove("neubrutalism");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <button
      onClick={() => setTheme(theme === "default" ? "neubrutalism" : "default")}
      className="px-4 py-2 rounded-lg  bg-accentColor text-background1 transition-all hover:bg-accent hover:text-black"
    >
      Switch to {theme === "default" ? "Neubrutalism" : "Default"} Theme
    </button>
  );
}

export const Chip = ({ name, icon }) => {
  return (
    <div className="bg-background1 font-sans flex flex-row border-[1px] font-[500] border-foreground1 items-center gap-[8px] rounded-[8px] w-max px-[16px]  py-[8px] text-foreground1 text-[14px]">
      {/* {icon} */}
      {name}
    </div>
  );
};

export const viewButton = () => {
  return (
    <div className="">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 16H9.41L17.71 7.71C17.8983 7.5217 18.0041 7.2663 18.0041 7C18.0041 6.7337 17.8983 6.47831 17.71 6.29C17.5217 6.1017 17.2663 5.99591 17 5.99591C16.7337 5.99591 16.4783 6.1017 16.29 6.29L8 14.59V7C8 6.73479 7.89464 6.48043 7.70711 6.2929C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.2929C6.10536 6.48043 6 6.73479 6 7V17C6.00158 17.1307 6.02876 17.2598 6.08 17.38C6.18147 17.6244 6.37565 17.8185 6.62 17.92C6.74022 17.9712 6.86932 17.9984 7 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17C18 16.7348 17.8946 16.4804 17.7071 16.2929C17.5196 16.1054 17.2652 16 17 16Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export const ShadowButton = ({ label }) => {
  return (
    <div className="px-[16px] tracking-[-0.56px] font-bold border-foreground1 border-[1px] shadow-[6px_6px_0px_rgba(0,0,0,1)] text-foreground1 bg-background1 text-[14px] font-mono  w-max rounded rounded-[8px] py-[8px] transition-all duration-300 ease-in-out cursor-pointer hover:shadow-[4px_4px_0px_rgba(94,43,255,1)] hover:translate-y-1 active:shadow-none active:translate-y-2">
      {label}
    </div>
  );
};

export const IconShadowButton = ({ icon, action }) => {
  return (
    <div
      className="px-[8px] py-[4px]  font-bold border-foreground1 border-[1px] shadow-[2px_2px_0px_rgba(0,0,0,1)] text-foreground1 bg-background1 text-[14px]  w-max rounded rounded-[8px] py-[8px] transition-all duration-300 ease-in-out cursor-pointer hover:shadow-[1px_1px_0px_rgba(94,43,255,1)] hover:translate-y-1 active:shadow-none active:translate-y-2"
      onClick={action}
    >
      {icon}
    </div>
  );
};
