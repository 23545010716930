import React from "react";

export const Pizza = () => {
  return (
    <svg
      width="99"
      height="87"
      viewBox="0 0 99 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.5" y="4.5" width="90.9152" height="77.8178" stroke="#4199F8" />
      <path
        d="M85.5285 39.1311L66.6861 68.138C60.6035 77.5021 46.7061 76.9083 41.4398 67.0644L38.1012 60.8151L35.5536 56.0468L35.5485 56.0437L25.3402 36.951L25.3352 36.9478L12 12L22.9428 12.7909L44.5876 14.3594L67.2178 15.9932C67.3623 16.004 67.5061 16.0141 67.6468 16.03L74.2815 16.5084C85.4194 17.3107 91.6111 29.7669 85.5285 39.1311Z"
        fill="#EFA630"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.1878 36.0561L60.4824 61.7733C55.0899 70.0747 42.7687 69.5449 38.1012 60.8151L35.5536 56.0469L35.5485 56.0437L25.3402 36.951L25.3352 36.9478L12 12L22.9428 12.7909L44.5876 14.3594C44.5907 14.3543 44.5907 14.3543 44.5907 14.3543L67.2172 15.9938C67.3617 16.0046 67.5055 16.0147 67.6462 16.0306C77.2512 17.0306 82.5004 27.8771 77.1878 36.0561Z"
        fill="#EFD552"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.6684 37.7626C40.6959 41.0282 42.1244 47.7528 38.8587 52.7803C37.9746 54.1409 36.8383 55.2392 35.5493 56.0492L35.5442 56.046L25.3359 36.9533C28.5737 35.5039 32.4636 35.6807 35.6684 37.7626Z"
        fill="#DC5A48"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.5872 14.3576C44.52 16.241 43.9503 18.1302 42.8488 19.8255C39.5794 24.8586 32.8554 26.2865 27.8222 23.0176C24.2879 20.7222 22.5299 16.7157 22.9418 12.7891L44.5872 14.3576Z"
        fill="#DC5A48"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.4092 40.7593C69.5927 37.7411 71.3481 31.0923 68.3299 25.9088C65.3116 20.7253 58.6628 18.9699 53.4793 21.9882C48.2958 25.0064 46.5405 31.6552 49.5587 36.8387C52.5769 42.0222 59.2257 43.7775 64.4092 40.7593Z"
        fill="#DC5A48"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.3006 50.5441C52.4357 51.2811 52.7576 52.7989 52.0205 53.9333C51.2835 55.0683 49.7657 55.3903 48.6313 54.6532C47.4963 53.9162 47.1744 52.3984 47.9114 51.264C48.6484 50.1296 50.1656 49.807 51.3006 50.5441Z"
        fill="#82BC68"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.9614 30.6889C42.547 31.0691 42.7137 31.8524 42.3328 32.438C41.9525 33.0235 41.1692 33.1902 40.5837 32.8094C39.9981 32.4291 39.8314 31.6458 40.2123 31.0602C40.5925 30.4746 41.3758 30.308 41.9614 30.6889Z"
        fill="#82BC68"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.9911 28.1809C30.9138 28.7804 31.1756 30.0137 30.5767 30.9364C29.9772 31.8591 28.7439 32.1209 27.8212 31.522C26.8984 30.9225 26.6367 29.6892 27.2356 28.7665C27.8351 27.8438 29.0684 27.582 29.9911 28.1809Z"
        fill="#82BC68"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="94.4922"
        cy="4.49219"
        r="3.99219"
        fill="white"
        stroke="#4199F8"
      />
      <circle
        cx="4.49219"
        cy="4.49219"
        r="3.99219"
        fill="white"
        stroke="#4199F8"
      />
      <circle
        cx="4.49219"
        cy="82.5078"
        r="3.99219"
        fill="white"
        stroke="#4199F8"
      />
      <circle
        cx="94.4922"
        cy="81.5078"
        r="3.99219"
        fill="white"
        stroke="#4199F8"
      />
    </svg>
  );
};
