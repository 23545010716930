import { useEffect, useState } from "react";
import { storage, ref, listAll, getDownloadURL } from "../firebaseConfig";

const useFetchImages = (folderPath) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const folderRef = ref(storage, folderPath);
      const res = await listAll(folderRef);
      const urls = await Promise.all(
        res.items.map((item) => getDownloadURL(item))
      );
      setImages(urls);
    };

    fetchImages();
  }, [folderPath]);

  return images;
};

export default useFetchImages;
