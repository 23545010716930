import { useState } from "react";
import { motion } from "framer-motion";
import { MapIcon } from "../../../Icons/HeroIcons";
import { Chip, ShadowButton } from "../../Buttons";
import ImageComparisonHero from "../../../assets/ImageComparisonHeroImage.webp";
import OffShoremapImage from "../../../assets/Offshoremap.webp";
import { useNavigate } from "react-router-dom";
import ImageComparison from "../../../assets/CaseStudyList/ImageComparison.webp";
import RealtimeMap from "../../../assets/CaseStudyList/RealtimeMap.webp";
import Trandscend from "../../../assets/CaseStudyList/Transcend.webp";

import {
  CalendarIcon,
  DesignationIcon,
  GELogo,
  TDLogo,
  TeamIcon,
  TranscendLogo,
} from "./ProjectCardIcons";

const cards = [
  {
    id: 1,
    title:
      "Designed an intuitive image comparison and annotation experience for turbines, boosting fault resolution by 45%.",

    overview:
      "Client is world’s largest renewable energy provider specialised in wind energy. Designed the end to end experience of drone blade image comparison. The solution not only helped in moving the metrics but also helped saving clients’s tons of money on third party tools. ",
    tags: ["Under NDA 🔒", "Enterprise", "Product Design"],
    image: ImageComparison,
    backgroundColor: "bg-[#CFE4FD]",
    icon: <MapIcon />,
    path: "/ImageComparison",
    clients: [
      { name: "Think Design", logo: <TDLogo /> },
      { name: "GE Renewable", logo: <GELogo /> },
    ],
    myRole: [{ role: "Product Designer", icon: <DesignationIcon /> }],
    timeline: [{ months: "Mar 2024 - Jun 2024", icon: <CalendarIcon /> }],
    team: [{ team: "4 People incl. project manager", icon: <TeamIcon /> }],
    Outcomes: [
      "Addition of  image comparison tool within native tool helped increase inspection efficiency by 55%.",
      "Process of identifying damages got easier.",
      "Damage resolution time reduced after addition of image comparison.",
      "Better data feeding to AI annotation from the native tool itself. ",
    ],
    buttonLabel: "[Read Case Study]",
  },
  {
    id: 2,
    title:
      "Crafted a digital map for planning and monitoring for engineers which reduced execution time by 40%.",

    overview:
      "One of the most exciting projects I worked on for a renewable energy client involved designing a real-time map experience for offshore sites. This solution enabled planners and site managers to track live vessels and oversee maintenance tasks on ocean turbines with greater efficiency.",
    tags: [
      "Under NDA 🔒",
      "Enterprise",
      "Product Design & Strategy",
      "Map Design",
    ],
    image: RealtimeMap,
    backgroundColor: "bg-[#CFE4FD]",
    icon: <MapIcon />,
    path: "/RealtimeMap",
    clients: [
      { name: "Think Design", logo: <TDLogo /> },
      { name: "GE Renewable", logo: <GELogo /> },
    ],
    myRole: [{ role: "Product Designer", icon: <DesignationIcon /> }],
    timeline: [{ months: "Nov 2023 - Jun 2024", icon: <CalendarIcon /> }],
    team: [{ team: "4 People incl. project manager", icon: <TeamIcon /> }],
    Outcomes: [
      "Bringing all Excel sheets and paperwork into a native digital tool helped streamline logging and tracking of ongoing activities at onshore sites.",
      "Reduced the site visit planning time to 60%, since everything became digital. ",
      "Secured project funding by presenting a proof of concept (PoC) before full-scale design & Research. ",
      "Collaborated with data science team for the first time.",
    ],
    buttonLabel: "[Read Case Study]",
  },
  {
    id: 3,
    title:
      "Redesigned a Collateral Management Tool, helping secure major funding from Citibank.",

    overview:
      "Transformed a legacy collateral management application for global banks, transforming its outdated interface into a modern, web-based UI. Focused on interface design with key contributions to enhancing the user experience.",
    tags: ["Under NDA 🔒", "GUI", "Data Visualisation", "Enterprise"],
    image: Trandscend,
    backgroundColor: "bg-[#CFE4FD]",
    icon: <MapIcon />,
    path: "https://www.figma.com/proto/Vns6qqgH9STxR5tOx5G7Io/Portfolio?node-id=3737-43860&p=f&viewport=-51943%2C-32992%2C0.43&t=3svPv3QkxLholT9Q-0&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=3737%3A43860",
    clients: [
      { name: "Think Design", logo: <TDLogo /> },
      { name: "Transcend Street", logo: <TranscendLogo /> },
    ],
    myRole: [{ role: "Product Designer", icon: <DesignationIcon /> }],
    timeline: [{ months: "Nov 2023 - Jun 2024", icon: <CalendarIcon /> }],
    team: [{ team: "4 People incl. project manager", icon: <TeamIcon /> }],
    Outcomes: [
      "The redesign helped client to raise funding from citibank. ",
      "New experience managed to increase the task completion rate by 40%.",
      "It was my first dark mode design for web.",
    ],
    buttonLabel: "[Read Case Study]",
  },
];

export default function CardGrid() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigation = useNavigate();
  const handleNavigation = (path) => {
    if (path.startsWith("http")) {
      // External link (e.g., Notion)
      window.open(path, "_blank", "noopener,noreferrer");
    } else {
      // Internal link
      navigation(path);
    }
  };

  const playClickSound = () => {
    const audio = new Audio("/ClickSound.mp3");
    audio.play();
  };

  return (
    <div className="grid grid-cols-12 items-center justify-center gap-[60px]">
      {cards.map((card, index) => {
        return (
          <motion.div
            key={index}
            className="col-span-12 flex flex-col md:flex-row gap-[38px]  py-[24px] cursor-pointer"
            onHoverStart={() => setHoveredIndex(index)}
            onHoverEnd={() => setHoveredIndex(null)}
          >
            {/* Left Section - Image */}
            <div className="w-full h-auto p-[16px] border-[1px] border-[#4199F8]  md:w-[50%] relative">
              <div className="absolute inset-0 flex justify-between items-center pointer-events-none">
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] left-[-4px]"></div>
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] right-[-4px]"></div>
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] left-[-4px]"></div>
                <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] right-[-4px]"></div>
              </div>
              <img
                src={card.image}
                className="object-fill w-full h-full  rounded-[10px]"
              />
              <div className="absolute bottom-[20px] right-[20px] p-[8px] bg-[#00000060] text-white rounded-[12px] text-[12px] font-bold">
                Image from
                <a className="underline" href="https://every.to/">
                  Every.com
                </a>
              </div>
            </div>

            {/* Right Section - Content */}
            <div className="w-full md:w-[50%] flex flex-col gap-[24px]">
              {/* Client Logos */}
              <div className="flex flex-wrap gap-[12px] items-center">
                <div className="flex flex-wrap gap-[16px] items-center">
                  {card.clients.map((client, i) => (
                    <div
                      key={i}
                      className="flex flex-row gap-[4px] items-center"
                    >
                      {client.logo}
                      <p className="text-[14px] font-semibold tracking-[-0.64px] leading-[160%]">
                        {client.name}
                      </p>
                    </div>
                  ))}
                </div>
                {/* Title */}
                <h2 className="text-dh1 font-bold font-script leading-[120%] break-words">
                  {card.title}
                </h2>
                {/* Role, Timeline, Team */}
                <div className="flex flex-wrap gap-[16px] pt-[8px] items-center">
                  {card.myRole.map((role, i) => (
                    <div key={i} className="flex gap-[4px] items-center">
                      {role.icon}
                      <p className="font-sans text-[14px] font-[400] text-[#00000066] font-sans tracking-[-0.64px] leading-[160%]">
                        {role.role}
                      </p>
                    </div>
                  ))}
                  {card.timeline.map((time, i) => (
                    <div key={i} className="flex gap-[4px] items-center">
                      {time.icon}
                      <p className="font-sans text-[14px] font-[400] text-[#00000066] font-sans tracking-[-0.64px] leading-[160%]">
                        {time.months}
                      </p>
                    </div>
                  ))}
                  {card.team.map((team, i) => (
                    <div key={i} className="flex gap-[4px] items-center">
                      {team.icon}
                      <p className="font-sans text-[14px] font-[400] text-[#00000066] font-sans tracking-[-0.64px] leading-[160%]">
                        {team.team}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Overview */}
              <div className="flex flex-col gap-[8px]">
                <p className="font-script text-dh2 text-text1 font-[400]">
                  Overview
                </p>
                <p className="text-db tracking-[-0.32px] leading-[150%]">
                  {card.overview}
                </p>
              </div>

              {/* Outcomes */}
              <div className="flex flex-col gap-[8px]">
                <p className="font-script text-dh2 text-text1 font-[400]">
                  Outcomes
                </p>
                <ul className="list-[square] pl-4 space-y-[8px] text-db tracking-[-0.32px] leading-[150%]">
                  {card.Outcomes.map((item, i) => (
                    <li key={i} className="pl-2">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Tags */}
              <div className="flex flex-wrap gap-[16px]">
                {card.tags.map((tag, i) => (
                  <div
                    key={i}
                    className="px-[12px] py-[4px] text-[13px] font-semibold bg-[#FCE9CD] rounded-full"
                  >
                    {tag}
                  </div>
                ))}
              </div>

              {/* Button */}
              <div
                onClick={() => {
                  setTimeout(() => {
                    window.location.href = card.path;
                  }, 150);
                  playClickSound();
                }}
              >
                <ShadowButton label={card.buttonLabel} />
              </div>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}
