import {
  NavMenuContainer,
  MenuItem,
  CloseIcon,
} from "../../styles/Navbar.styled";

import Button, {
  ButtonV2,
  PrimaryButton,
  ThemeSwitch,
  ThemeSwitcher,
} from "../Buttons";

import { motion } from "framer-motion";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

// const sendEmail =
//   "mailto:workwithpunitgupta@gmail.com? subject=SendMail & body=Hey Buddy";

export const NavMenu = ({ setOpenMenu }) => {
  const navigation = useNavigate();

  const [isActive, setActive] = useState("/");
  const path = window.location.pathname;

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
    >
      <NavMenuContainer>
        {/* --menu-itmes-- */}

        {/* <ThemeSwitcher /> */}
        {/* <ThemeSwitch/> */}
        <ButtonV2 label={"HOME"} action={() => navigation("/")} />

        <ButtonV2 label={"ABOUT ME"} action={() => navigation("/AboutMe")} />

        {/* <ButtonV2 label={"LIBRARY"} action={() => navigation("/MyLibrary")} /> */}
        <ButtonV2 label={"RESUME"} action={() => window.open("resume.pdf")} />

        {/* <CloseIcon onClick={() => setOpenMenu(false)}>
        <Close />
      </CloseIcon> */}
      </NavMenuContainer>
    </motion.div>
  );
};
