import React from "react";
import useFetchImages from "../../../utils/FetchImages";

const extractTitleFromUrl = (url) => {
  try {
    const decodedUrl = decodeURIComponent(url);
    const fileName = decodedUrl.split("/").pop();
    if (!fileName) return "Image";
    const nameWithoutExtension = fileName.split(".")[0];
    if (nameWithoutExtension.toLowerCase().startsWith("img")) {
      const number = nameWithoutExtension.replace(/\D/g, "");
      return `Image ${number}`;
    }
    return nameWithoutExtension;
  } catch (error) {
    return "Image";
  }
};

export const CuratedUIWall = () => {
  const CuratedImages = useFetchImages("VisualWall");
  return (
    <div className="grid grid-cols-12 gap-[16px] h-[auto]">
      <div className=" col-span-12 col-start-1 flex gap-[16px]">
        {CuratedImages.map((url, i) => (
          <span
            key={i}
            className=" h-[364px] w-[330px] justify-center items-center gap-[16px] text-center relative inline-flex flex-col  p-[16px] border-[1px] border-[#4199F8] "
          >
            <div className="w-[295px] h-[295px] ">
              <img src={url} className="object-cover w-full h-full" />
            </div>
            <p className=" font-mono text-foreground1 opacity-[60%] text-[12px] ">
              {extractTitleFromUrl(url)}
            </p>
            <div className="absolute inset-0 flex justify-between items-center pointer-events-none">
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] left-[-4px]"></div>
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute top-[-4px] right-[-4px]"></div>
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] left-[-4px]"></div>
              <div className="w-2 h-2 bg-[#fff] border-[1px] border-[#4199F8] rounded-full absolute bottom-[-4px] right-[-4px]"></div>
            </div>
          </span>
        ))}
      </div>
    </div>
  );
};
