import {
  Body,
  GiantHeading,
  Heading,
  Sp1,
  Sp2,
  Subheading,
  Title,
} from "../../styles/CaseStudyDetail.styled";
import { Chip } from "../Buttons";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Navbar } from "../Navbar";

export const MyLibrary = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div>
      <div className="bg-background flex flex-col  mt-[20vh] mx-[5vw] w-[auto]  h-[auto] ">
        <div className="mb-[70px] h-[2rem]  ">
          <GiantHeading> All Components</GiantHeading>
        </div>
        <div>
          <div className=" relative flex w-full flex-row items-start gap-20 ">
            <div className="flex flex-col w-[350px] gap-8">
              <div>
                <Subheading> Vintage Button </Subheading>
                <Sp1 />

                <Body
                  style={{
                    color: "hsl(var(--nextui-foreground2))",
                    width: "100%",
                  }}
                >
                  A Vintage button with an inner <br /> shadow and a hover
                  interaction.{" "}
                </Body>
              </div>
              <div className="flex gap-2">
                <Chip name={"react"} />
                <Chip name={"framer motion"} />
                <Chip name={"tailwind"} />
              </div>
            </div>
            <div className="flex items-center h-[400px] w-full rounded-lg justify-center border-1 border-background2 bg-background ">
              <AnimatePresence>
                <motion.div layout>
                  {isOpen || (
                    <motion.div
                      exit={{ opacity: 0 }}
                      whileHover={{ scale: 1.1 }}
                      onClick={() => setOpen(!isOpen)}
                      style={{
                        backgroundColor: "#282828",
                        borderColor: "#000000",
                        width: "max-content",
                        padding: "1rem",
                        borderRadius: "16px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        height: "max-content",
                        boxShadow: "0px 1px 1px 0px #5e5d5d inset",
                      }}
                    >
                      Click Me
                    </motion.div>
                  )}

                  {isOpen && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className=" flex w-[504px] h-[333px] bg-background justify-between flex flex-col "
                      style={{
                        background:
                          "linear-gradient(-57deg, #222222 100%,#2F2F2F 7%)",
                        borderRadius: "16px",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderRadius: "16px",
                        boxShadow: "0px 1px 1px 0px #5e5d5d inset",
                        padding: "27px",
                      }}
                    >
                      <Heading>New Card for Anything</Heading>
                      <div className=" flex flex-col align-end">
                        <div className="flex flex-row justify-between ">
                          <span>Last Updated</span>
                          <span>24 Feb 24</span>
                        </div>
                        <div
                          className="w-full bg-background h-[1.5px] my-2 "
                          style={{ boxShadow: "0px 1px 1px 0px #333333 " }}
                        ></div>
                        <div className="flex gap-2 self-end mt-1">
                          <motion.div
                            whileHover={{ scale: 1.1 }}
                            onClick={() => setOpen(!isOpen)}
                            style={{
                              backgroundColor: "#282828",
                              borderColor: "#000000",
                              width: "max-content",
                              padding: "1rem",
                              borderRadius: "16px",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              height: "max-content",
                              boxShadow: "0px 1px 1px 0px #5e5d5d inset",
                            }}
                          >
                            Cancel
                          </motion.div>
                          <motion.div
                            whileHover={{ scale: 1.1 }}
                            onClick={() => setOpen(!isOpen)}
                            style={{
                              backgroundColor: "#282828",
                              borderColor: "#000000",
                              width: "max-content",
                              padding: "1rem",
                              borderRadius: "16px",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              height: "max-content",
                              boxShadow: "0px 1px 1px 0px #5e5d5d inset",
                            }}
                          >
                            Done
                          </motion.div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
