import React from "react";
import { ShadowButton } from "../../Buttons";
import { LinkedinIcon, TwitterIcon } from "../../../Icons/HeroIcons";

export const Footer1 = () => {
  const openEmailClient = () => {
    window.location.href = "mailto:";
  };
  return (
    <div className="grid grid-cols-12  mx-[48px] flex-col items-center mt-[124px] mb-[60px] w-full  pt-[80px] gap-[16px] h-[auto] mx-[48px] max-w-[1470px]  bg-background1">
      <div className=" col-start-1 col-span-12  mx-[48px] gap-[24px] items-center border-t-[1px] border-foreground1  h-[auto] flex flex-col  text-foreground1">
        <div className="flex flex-col gap-[4px]">
          <div className=" text-foreground1 text-center font-serif font-[700] text-[38px] mt-[48px] ">
            Leaving Too soon?
          </div>
          <div className="text-foreground1 text-center font-sans text-[16px] tracking-[-0.96px]">
            {" "}
            Don’t be shy! Wanna jam or chit chat? I am always up.
          </div>
        </div>
        <div onClick={() => openEmailClient()}>
          <ShadowButton label={"[Buzz Me]"} />
        </div>
        <div className="flex pt-[24px] gap-[48px] align-center justify-start ">
          <div
            className="cursor-pointer"
            onClick={() =>
              window.open("https://www.linkedin.com/in/workwithpunitgupta/")
            }
          >
            <LinkedinIcon />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => window.open("https://twitter.com/DJ_iner")}
          >
            <TwitterIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
