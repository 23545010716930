"use client";

import React from "react";
import { Star1 } from "../../../Icons/Stars";
import { GamingController } from "../../../Icons/HeroIcons";
import { MarqueeText } from "../NewComponents/MarqueeText";
import Lottie, { LottiePlayer } from "lottie-react";
import FloatingTooltip from "../NewComponents/HoverWrapper";
import { Music, Coding } from "../../../Icons/HeroIcons";
import { FloatingWrapper } from "../NewComponents/FloatingWrapper";
import { Star2 } from "../../../Icons/Stars";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { useRef } from "react";
import cloudAnimation from "../../../assets/Cloud.json";
import eyeAnimation from "../../../assets/eye.json";
import heatAnimation from "../../../assets/Heart.json";

export const HeroSection1 = () => {
  const Texts = [
    "experience design",
    <Star2 />,
    "product design",
    <Star2 />,
    "interaction design",
    <Star2 />,
    "motion design",
    <Star2 />,
    "design research",
    <Star2 />,
    "data visualization",
    <Star2 />,
    "Design Strategy",
    <Star2 />,
    "advance prototyping",
    <Star2 />,
  ];

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const springConfig = { damping: 20, stiffness: 60 };

  const moveLeft = useSpring(
    useTransform(scrollYProgress, [1, 0], [0, 800]),
    springConfig
  ); // Moves left out
  const moveRight = useSpring(
    useTransform(scrollYProgress, [1, 0], [0, -800]),
    springConfig
  );
  const moveUp = useSpring(
    useTransform(scrollYProgress, [1, 0], [0, -100]),
    springConfig
  );

  return (
    <div
      className="  w-full   flex flex-col items-center justify-center  "
      ref={ref}
    >
      <div className="flex flex-col h-screen  items-center justify-center">
        <div className="inline-flex justify-center items-baseline space-x-[2px]">
          <p className="  text-d1 font-d1 text-foreground1 text-center font-serif font-d1 leading-[160%]">
            What if design could speak to the eyes
            <span className="inline-block  mx-1">
              <Lottie
                animationData={eyeAnimation}
                loop={true}
                width="56px"
                height="56px"
              />
            </span>
            , the mind
            <span className="inline-block  mx-1">
              <Lottie
                animationData={cloudAnimation}
                loop={true}
                width="56px"
                height="56px"
              />
            </span>
            and the heart
            <span className="inline-block  mx-1">
              <Lottie
                animationData={heatAnimation}
                loop={true}
                width="56px"
                height="56px"
              />
            </span>
            ?
          </p>
        </div>
        <div className="text-foreground1 font-sans tracking-[-0.64px] font-body leading-[160%] px-[100px] mt-[24px] text-center">
          That’s the{" "}
          <span className="font-[700]">
            mission I have been on from last 4+ years.{" "}
          </span>
          Blending mental models with design that{" "}
          <span className="font-[700]">solve problems</span> and{" "}
          <span className="font-[700]">evoke emotion.</span> Beyond pixels,
          you'll find me exploring sound, movement and strategizing in Age of
          Empires.
        </div>
        <div className=" px-[16px] py-[8px] mt-[48px] text-[14px] font-[600] text-foreground1 font-sans h-[auto] w-max-content inline-flex gap-[8px] items-center justify-center rounded-full bg-[#FCE9CD]">
          <Star1 /> Current: Sr. UX Designer@Think Design
        </div>
      </div>
      <motion.div
        className=" relative  right-[1350px] top-[-500px]"
        style={{ x: moveLeft }}
      >
        <FloatingWrapper
          children={
            <FloatingTooltip
              children={<GamingController />}
              tooltipText={"Mastering age of empires, one strategy at a time."}
            />
          }
        />
      </motion.div>
      <motion.div
        className=" relative left-[1300px]  top-[-400px]"
        style={{ x: moveRight }}
      >
        <FloatingWrapper
          children={
            <FloatingTooltip
              children={<Coding />}
              tooltipText={"Brining  pixels to life using react and motion"}
            />
          }
        />
      </motion.div>
      <motion.div
        className=" relative left-[1400px]  top-[-800px]"
        style={{ x: moveRight }}
      >
        <FloatingWrapper
          children={
            <FloatingTooltip
              children={<Music />}
              tooltipText={"Not only pixels, I design sound as well"}
            />
          }
        />
      </motion.div>

      <div className=" absolute top-[90vh] w-[100%]  ">
        <MarqueeText texts={Texts} bgColor={"#F78A4C"} />
      </div>
    </div>
  );
};
